import React, { useContext, useEffect } from "react";
import { CiBank } from "react-icons/ci";
import { useState } from "react";
import { BsBank2 } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import useTranslator from "../../hooks/useTranslator";
import axios from "axios";
import { toast } from "react-toastify";
import { haveValue } from "../../helper/common";
import AuthContext from "../../context/auth/AuthContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { sendUniversalMail } from "../../services/sharedService";
import AlreadyGuarantorOf from "../AlreadyGuarantorOf";
import LoaderBank from "../LoaderBank";
import ScoreWaitModal from "../ScoreWaitModal";
import SomeoneGuarantor from "../SomeoneGuarantor";
import TenantContext from "../../context/tenant/TenantContext";

const BankingProcess = (props) => {

  const { setloader1, } = props
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, loadUser, setLoading, setBankLoading, setAlgoanLoading, loadUserTenant } = authContext;
  const { skipVerification } = tenantContext
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const [showPendingScreen, setShowPendingScreen] = useState(false)
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false)
  const [gurantorPresent, setGurantorPresent] = useState(false)
  const [waitBank, setWaitBank] = useState(false)


  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const error = queryParameters.get("error");
  const error_message = queryParameters.get("error_reason");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {


    if (error) {
      toast.error(_t("Bank Details cannot be fetched!"));
    }
    if (account_verification_report_id && transaction_report_id) {
      // snackbar.showMessage(_t("Connected to Bank"));
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("User Not Authorized"));
      } else {
        console.log("Calculate Score============>");
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    console.log("payment score calculations");
    // setBankDiv("kyc-loading");
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    console.log("Clearing Payment Data ===========>> ",);
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          console.log(res);
          console.log("previous data cleared");
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    setBankLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        console.log(serverResponse);
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {

            // props?.setAlgoanStatus('pending')

            // toast.error(_t("Analysis Pending. Please try again!"));
            getAnalysisDetails()
            setAlgoanLoading(true)
            setShowPendingScreen(true)
            setBankLoading(false)
            // setCrediterr(serverResponse);
            // setBankDiv("algoan-pending");
            // console.log("in error state", crediterr);
          } else {
            toast.error(_t("Credit score can not be calculated"));
            // setCrediterr(serverResponse);
            // setBankDiv("kyc-error");
            // console.log("in error state", crediterr);
            setBankLoading(false)
          }
        }
        if (serverResponse.status === "success") {
          toast.success(_t("Bank Connected Successfully!"))
          let usertype = localStorage.getItem("usertype");
          loadUser(usertype);
          // setBankDiv("kyc-loaded");
          setBankLoading(false)
        }
      })
      .catch((err) => {
        let err_res = err;
        setBankLoading(false)
        console.log(err_res);
      });
  };

  const getAnalysisDetails = async () => {
    let userid = localStorage.getItem("saveduserid");
    // setLoading(true);
    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          setAlgoanLoading(false)
          clearInterval(myInterval);
          const userType = localStorage.getItem("usertype")
          loadUser(userType);
        })
        .catch((err) => {
          setAlgoanLoading(false)
          toast.error(_t("Credit score can not be calculated"));
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };



  const [Bankshow, BanksetShow] = useState(false);
  const BankhandleClose = () => BanksetShow(false);
  const bankhandleShow = () => BanksetShow(true);
  const [show, setShow] = useState(false);

  const [countryCodeError, setCountryCodeError] = useState("");

  const [tink_countryCode, setTink_countryCode] = useState("");

  const [showGurantor, setShowGurantor] = useState(false);
  const [showSkipUpload, setShowSkipUpload] = useState(false);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
    analysisCompleted: true
  })

  const handleShowGurantor = () => setShowGurantor(true)
  const handleHideGurantor = () => {
    setShowGurantor(false)
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
      analysisCompleted: true
    })
  }
  const handleHideSkip = () => {
    bankhandleShow()
    setShowSkipUpload(false)
  }

  const handleGurantorModal = () => {
    console.log("CLICKED")
    handleShowGurantor()
  }

  const handleSubmitGurantor = async (e) => {
    e.preventDefault()
    let userid = localStorage.getItem("saveduserid");
    setLoading(true)
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`, guarantorData, { headers: { "Accept-Language": localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setLoading(false)
          handleHideGurantor()
          setGurantorPresent(true)
          setAlreadyGurantorOf(true)
          // setGurantorPresent(val => {console.log(val);return !val;})
          // setAlreadyGurantorOf(val => {console.log(val);return !val;})
          console.log("ALREADY PRESENT PARVEJ")
        }
        else {
          loadUser('tenant')
          toast.success(_t("Guarantor Added!"));
          handleHideGurantor();
          sendMail(guarantorData?.gurantor_email, "score");
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error)
        setLoading(false)
        //toast.error("Kyc Report Not Fetched")
      });
    console.log("SUBMITTED")
  }

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("Email Sent"));
        console.log(res);
      })
      .catch((err) => {
        snackbar.showMessage(_t("Error Sending Mail"), err?.message);
        console.log(err);
      });
  };

  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("*Please select country code");
      return;
    } else {
      setCountryCodeError('');
    }
    // let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fhome&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    // 
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Fhome&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    window.open(tinkUrlLiveFront, "_self");
  };
  const handleSkipPart = async () => {
    setLoading(true)
    try {
      await skipVerification(localStorage.getItem('saveduserid'))
      await loadUserTenant('tenant')
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }

  }


  return (
    <>
      {
        showPendingScreen ?
          <div className="vreify-box">
            <div className="verify-detail">
              <div className="detailin">
                <h4>{_t("Generating Score. It make take some time.")}</h4>
              </div>
            </div>
          </div>
          :
          <div className="vreify-box">
            <div className="verify-detail">
              <CiBank />
              <div className="detailin">
                <h4>{_t("Please Verify Your Funds")}</h4>
                <p>{_t("This will help prove you’re financially reliable and lower security deposits")}</p>
              </div>
            </div>
            <div className="verify-btn">
              {
                props?.algoanStatus != 'pending' &&
                <button
                  className="btn btnorange w-100"
                  onClick={bankhandleShow}
                  type="button"
                >
                  {_t("Connect Bank")}
                </button>
              }
              {
                props?.algoanStatus == 'pending' &&
                <div className="status_bar"><h5>{_t("Connection In Progress! It may take few minutes.")}</h5></div>
              }
            </div>
            {
              haveValue(user?.gurantor_for) && haveValue(user?.gurantor_for?._id) ?
                null
                :
                <div className="Guarantorbar">
                  <div className="divider">{_t("OR")}</div>
                  <button className="btn btnoutline" onClick={handleGurantorModal}> {_t("Add Guarantor")}</button>
                </div>
            }
            <h3 className="setNoInfoColor">
              <a
                href={undefined}
                onClick={e => setShowSkipUpload(true)}
              >
                {_t("Skip to upload documents")}
              </a>
            </h3>
          </div>
      }
      <Modal show={Bankshow} onHide={BankhandleClose} centered className="newmodalui connectbankmodal" size="lg">
        {/* <Modal.Header closeButton>
          <Modal.Title>{_t("Connect Bank")}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-sm-6 p-2 bankimagediv">
              <p>{_t("You will now be redirected to connect your bank account through our partner - Tink, powered by Visa.")}</p>
              <p>{_t("It's important for you to know that we do not have direct access to your bank login details. The connection process is designed to keep your credentials private and unseen by us or any other third parties.")}</p>
              <img src="../images/connect_bank.svg" alt="bank_logo" />
              <p>{_t("We assure you of the utmost privacy and security of your financial data.")}</p>
            </div>
            <div className="col-sm-6 p-2 connectbankdiv">
              <h3>{_t("Connect Bank")}</h3>
              <Form className="loginform">
                <div className="auth-form ">

                  <div className="forminput row100">
                    <label className="forminput">{_t("Country")}</label>
                    <select className="form-control" onChange={changeCountryCode}>
                      <option value="">{_t("Select your country")}</option>
                      <option value="BE">Belgium</option>
                      <option value="DE">Germany</option>
                      <option value="DK">Denmark</option>
                      <option value="EE">Estonia</option>
                      <option value="ES">Spain</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GB">United Kingdom</option>
                      <option value="IT">Italy</option>
                      <option value="LT">Lithuania</option>
                      <option value="LV">Latvia</option>
                      <option value="NL">Netherlands</option>
                      <option value="NO">Norway</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="SE">Sweden</option>
                    </select>
                  </div>
                  <div className="forminput row100">
                    <label className="forminput">{_t("Connect Bank")}</label>

                    <div className="bankname" onClick={connectToBank}>
                      <BsBank2 />{_t("Connect bank")}
                    </div>
                    <span className="addRedColor">
                      {countryCodeError}
                    </span>
                  </div>
                </div>
              </Form>
              <div className="bank-btm">
                {_t("Should you have any concerns or questions please ")}
                <a href={undefined} onClick={e => navigate('/bank')} className="btnlink">{_t("read here")}</a>
                {_t(" or contact us at ")}
                <a href="mailTo:info@skortorent.com" className="btnlink">info@skortorent.com.</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showGurantor} onHide={handleHideGurantor} centered className="newmodalui">
        <Modal.Header closeButton>
          <Modal.Title>{_t("Add Guarantor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={handleSubmitGurantor}>
            <div className="auth-form">
              <div className="forminput row100">
                <label className="forminput">{_t("Name Of Guarantor")}</label>
                <input type="text" name="gurantor_name" placeholder="Name Of Guarantor" onChange={e => setGuarantorData(prevData => ({ ...prevData, gurantor_name: e.target.value }))} value={guarantorData.gurantor_name} />
              </div>
              <div className="forminput row100">
                <label className="forminput">{_t("Email Of Guarantor")}</label>
                <input type="text" name="gurantor_email" placeholder="Email Of Guarantor" onChange={e => setGuarantorData(prevData => ({ ...prevData, gurantor_email: e.target.value.toLowerCase() }))} value={guarantorData.gurantor_email} />
              </div>
              <div className="btngroup row100 gridbar">
                <button className="btn btnoutline" type="button" onClick={handleHideGurantor}>
                  {_t("Cancel")}
                </button>
                <button className="btn btnorange" type="submit" disabled={(haveValue(guarantorData.gurantor_name) && haveValue(guarantorData.gurantor_email)) ? false : true}>
                  {_t("Submit")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showSkipUpload} onHide={handleHideSkip} centered className="newmodalui">
        <Modal.Header closeButton>
          <Modal.Title>{_t("Are You Sure You Want To Skip")}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth-form">
            <p className="skip_modal">
              {_t("Digital fund verification helps you:")}
            </p>
            <p className="skip_modal">
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L11 6.5L0 13V0Z" fill="#253837" />
              </svg>
              {_t("Enhance landlord approval and sets you apart from other applicants")}.
            </p>
            <p className="skip_modal">
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L11 6.5L0 13V0Z" fill="#253837" />
              </svg>
              {_t("Demonstrate financial stability to reduce security deposits")}.
            </p>
            <div className="btngroup row100 gridbar">
              <button className="btn btnoutline" type="button" onClick={handleSkipPart}>
                {_t("Continue")}
              </button>
              <button className="btn btnorange" type="button" onClick={handleHideSkip} >
                {_t("Verify Funds")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        alreadyGurantorOf && gurantorPresent == true &&
        <SomeoneGuarantor alreadyGurantorOf={alreadyGurantorOf} setAlreadyGurantorOf={setAlreadyGurantorOf} gurantorPresent={gurantorPresent} setGurantorPresent={setGurantorPresent} />
      }
    </>
  );
};

export default BankingProcess;
