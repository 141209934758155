import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRouteLandlord = ({ auth, component, ...rest }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("usertype") != "landlord") {
      navigate("/tenant");
    }
  }, []);

  return component;
};

export default ProtectedRouteLandlord;
