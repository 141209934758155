import constants from "../../Utils/constants"
import { haveValue } from "../../helper/common"
import useTranslator from "../../hooks/useTranslator"

const ReviewRentalPaid = (props) => {

    const { formData, step } = props
    const { _t } = useTranslator()
    const landlordName = `${haveValue(formData?.landlord_id?.fname_companyName) ? formData?.landlord_id?.fname_companyName : ''} ${haveValue(formData?.landlord_id?.lname) ? formData?.landlord_id?.lname : ''}`
    const tenantName = `${haveValue(formData?.tenant_id?.fname) ? formData?.tenant_id?.fname : ''} ${haveValue(formData?.tenant_id?.lname) ? formData?.tenant_id?.lname : ''}`
    const propAddr = `${formData?.property_id?.street} - ${formData?.property_id?.street_number}, ${formData?.property_id?.floor_number}-${formData?.property_id?.flat_number}`


    const calculatePaymentDate = (date) => {
        const todayDate = new Date();
        var currentYear = todayDate.getFullYear();
        var currentMonth = todayDate.getMonth();
        var currPayDay = new Date(currentYear, currentMonth, date);

        if (currPayDay < todayDate || currPayDay < new Date(formData?.payment_start)) {
            while (currPayDay < todayDate || currPayDay < new Date(formData?.payment_start)) {
                currentMonth++;

                if (currentMonth > 11) {
                    currentMonth = 0; // January
                    currentYear++;
                }
                currPayDay = new Date(currentYear, currentMonth, date)
            }

            return currPayDay.toLocaleDateString('en-GB');
        } else {
            return currPayDay.toLocaleDateString('en-GB');
        }
    }
    return (
        
        <>
        
            <div className="reivew-page">
            {
                step == 0 ?
                    <div className="reviewandconform uiux">
                        <div className="reviewandconform-body">
                            <h3>{_t("Review")}</h3>

                            <div className="payment-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>{_t("Move-in address")}</h6>
                                        <p>{landlordName}</p>
                                        <p>{propAddr}</p>
                                    </div>
                                    <div className="col-sm-12">
                                        <p>{new Date(formData?.payment_start).toLocaleDateString('en-GB')} {haveValue(formData?.lease_end) && `- ${new Date(formData?.lease_end).toLocaleDateString('en-GB')}`}</p>
                                    </div>
                                    {

                                        <>
                                            <div className="col-6">
                                                <h4 className="rent-headingtext"> {_t("Rent")} </h4>
                                                </div>
                                                <div className="col-6">
                                                    <p>€{formData?.rent_amount}</p>
                                                    <p> {_t("Monthly")} </p>
                                                </div>
                                            

                                            {
                                                formData?.deposit_type == constants.SKOR_DEPOSIT ?
                                                    <>
                                                        <div className="col-6">
                                                            <h4 className="rent-headingtext"> {_t("Skor Deposit")} </h4>
                                                            {/* <h4> {_t("")} </h4> */}
                                                            </div>
                                                            <div className="col-6">
                                                                <p>€{formData?.monthly_sub_amount}</p>
                                                                <p> {_t("monthly subscription")} </p>
                                                            </div>
                                                        
                                                        <div className="col-6">
                                                            <h4 className="rent-headingtext"> {_t("Total Monthly Payment")}</h4>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>€{+formData?.rent_amount + +formData?.monthly_sub_amount}</p>
                                                            </div>
                                                        
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-6">
                                                            <h4 className="rent-headingtext"> {_t("Total Monthly Payment")}</h4>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>€{+formData?.rent_amount}</p>
                                                            </div>
                                                        
                                                    </>
                                            }
                                            <div className="col-sm-6">
                                                <p>{_t("Starting")} {calculatePaymentDate(formData?.payment_date)}</p>
                                            </div>
                                        </>

                                    }


                                </div>
                            </div>
                        </div>
                    </div >
                    :

                    <div className="review-pay">
                        <h3> {_t("Review")} </h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <circle cx="16" cy="16" r="16" fill="#A3EB64"/>
  <path d="M11.8654 20.314L7.93019 16.4506C7.71814 16.2425 7.43055 16.1255 7.13068 16.1255C6.8308 16.1255 6.54321 16.2425 6.33117 16.4506C6.11912 16.6588 6 16.9412 6 17.2356C6 17.3813 6.02925 17.5257 6.08607 17.6604C6.14289 17.795 6.22617 17.9174 6.33117 18.0205L11.0715 22.6743C11.5138 23.1086 12.2283 23.1086 12.6705 22.6743L24.6688 10.895C24.8809 10.6868 25 10.4044 25 10.11C25 9.81564 24.8809 9.5333 24.6688 9.32512C24.4568 9.11695 24.1692 9 23.8693 9C23.5695 9 23.2819 9.11695 23.0698 9.32512L11.8654 20.314Z" fill="#223635"/>
</svg>
                    </div>

            }
            </div>
            
        </>
    )
}


export default ReviewRentalPaid