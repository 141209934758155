import { useContext, useEffect, useState } from "react";
import { haveValue } from "../../helper/common"
import useTranslator from "../../hooks/useTranslator"
import { BsExclamationCircleFill } from "react-icons/bs";
import moment from "moment";
import LandLordContext from "../../context/landlord/LandLordContext";
import { toast } from "react-toastify";
import TenantContext from "../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import constants from "../../Utils/constants";
import AuthContext from "../../context/auth/AuthContext";

const ReviewAssignProp = (props) => {

    const tenantContext = useContext(TenantContext)
    const { acceptAssignedProp, checkUelzStatus } = tenantContext
    const authContext = useContext(AuthContext);
    const { setLoading } = authContext
    const { formData, handleNextStep } = props
    const landlordName = `${haveValue(formData?.landlord_id?.fname_companyName) ? formData?.landlord_id?.fname_companyName : ''} ${haveValue(formData?.landlord_id?.lname) ? formData?.landlord_id?.lname : ''}`
    const tenantName = `${haveValue(formData?.tenant_id?.fname) ? formData?.tenant_id?.fname : ''} ${haveValue(formData?.tenant_id?.lname) ? formData?.tenant_id?.lname : ''}`
    const propAddr = `${formData?.property_id?.street} - ${formData?.property_id?.street_number}, ${formData?.property_id?.floor_number}-${formData?.property_id?.flat_number}`

    const [editDate, setEditDate] = useState(false)
    const [payment_date, setPaymentDate] = useState(formData?.payment_date);
    const [intervalSucceed, setIntervalSucceed] = useState(false);
    const navigate = useNavigate()
    const amountDeduct = formData?.rent_amount + formData?.deposit_amount

    const { _t } = useTranslator()

    const handleKeyDown = (event) => {
        event.preventDefault();
    };

    const calculatePaymentDate = (date) => {
        const todayDate = new Date();
        var currentYear = todayDate.getFullYear();
        var currentMonth = todayDate.getMonth();
        var currPayDay = new Date(currentYear, currentMonth, date);

        if (currPayDay < todayDate || currPayDay < new Date(formData?.payment_start)) {
            while (currPayDay < todayDate || currPayDay < new Date(formData?.payment_start)) {
                currentMonth++;

                if (currentMonth > 11) {
                    currentMonth = 0; // January
                    currentYear++;
                }
                currPayDay = new Date(currentYear, currentMonth, date)
            }

            return currPayDay.toLocaleDateString('en-GB');
        } else {
            return currPayDay.toLocaleDateString('en-GB');
        }
    }
    /*useEffect(() => {
        const existingScript = document.querySelector('script[src="https://uelzpay-widget-cdn-demo.vercel.app/v1.0.0/uelz-widget.js?uelz-api-key=clv3lz7bw000xnh01gy64vwsy&uelz-api-url=https://widget.demo.uelzpay.com"]');


        if (existingScript) {
            // existingScript.parentNode.removeChild(existingScript); // Remove the existing script element
            window.location.reload()
        }

        setTimeout(() => {
            var script = document.createElement('script');
            script.src = 'https://uelzpay-widget-cdn-demo.vercel.app/v1.0.0/uelz-widget.js?uelz-api-key=clv3lz7bw000xnh01gy64vwsy&uelz-api-url=https://widget.demo.uelzpay.com';
            script.onload = function () {
                console.log('Script loaded successfully.');
            };
            script.onerror = function () {
                console.error('Failed to load script.');
            };
            document.getElementById("uelzpayWrapper").appendChild(script);
        }, 200);

    }, []);*/


    const handleSubmitChanges = async () => {
        if (!haveValue(payment_date)) {
            toast.error(_t("Payment Date is required!"))
            return;
        }
        if (moment(calculatePaymentDate(payment_date), 'DD/MM/YYYY') > moment(calculatePaymentDate('5'), 'DD/MM/YYYY')) {
            toast.error(_t("Date cannot be greater than 5"))
            return;
        }
        const data = {
            payment_date: payment_date,
            status: constants.AWARDED
        }
        acceptAssignedProp(data, formData?._id)
            .then(res => {
                navigate(`/awarded/property/${formData?._id}?confirmation=true`)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })

    }

    const checkElementOnInterval = () => {
        var intervalId;

        intervalId = setInterval(() => {
            const checkElement = document.querySelector("#uelz-widget-modal #success-page")
            if (haveValue(checkElement)) {
                clearInterval(intervalId);
                setTimeout(() => {
                    document.querySelector("#uelz-widget-modal .modal-header img").click()
                    checkSuccessPayload();
                }, 2000);
            }
        }, 500);
    }

    const checkSuccessPayload = () => {
        setLoading(true)
        checkUelzStatus(formData?._id)
            .then(res => {
                if (res?.success_res?.data?.status == constants.COMPLETED) {
                    setLoading(false)
                    navigate(`/awarded/property/${formData?._id}?confirmation=true`)
                } else {
                    checkSuccessPayload();
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }


    return (
        <>
            <div className="reviewandconform uiux">
                <div className="reviewandconform-body">
                    <h3>{_t("Review")}</h3>

                    <div className="payment-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{_t("Move-in address")}</h6>
                                <p>{landlordName}</p>
                                <p>{propAddr}</p>
                            </div>
                            <div className="col-sm-12">
                                <h6> {_t("Tenant")} </h6>
                                <p>{tenantName}</p>
                                <p>Skor: {haveValue(formData?.tenant_id?.paymentScores) ? formData?.tenant_id?.paymentScores.creditScore.value : 0} </p>
                            </div>
                            <div className="col-sm-6">
                                <h6>{_t("Lease start date")}</h6>
                                <p>{new Date(formData?.payment_start).toLocaleDateString('en-GB')}</p>
                            </div>
                            <div className="col-sm-6">
                                <h6>{_t("Lease end date")}</h6>
                                <p>{haveValue(formData?.lease_end) ? new Date(formData?.lease_end).toLocaleDateString('en-GB') : _t('Open')}</p>
                            </div>
                            <div className="col-sm-12">
                                <h6> {("Is it a shard household")} </h6>
                                <p className="text-capitalize">{formData?.shared_household}</p>
                            </div>
                            <div className="col-sm-6">
                                <h6>{_t("Rental payment amount")}</h6>
                                <p>{formData?.rent_amount}</p>
                            </div>
                            <div className="col-sm-6">
                                <h6>{_t("Deposit amount")}</h6>
                                <p>{formData?.deposit_amount}</p>
                            </div>
                            <div className="col-sm-12">
                                <h6 className="date-payment">{("Payment Date")}<span>*</span></h6>
                                {
                                    editDate ?
                                        <>
                                            <input className="choose-date" type="date" onKeyDown={handleKeyDown} onChange={e => setPaymentDate(new Date(e.target.value).getDate())} value={moment(calculatePaymentDate(payment_date), 'DD/MM/YYYY').format('YYYY-MM-DD')} min={moment(calculatePaymentDate('1'), 'DD/MM/YYYY').format('YYYY-MM-DD')} max={moment(calculatePaymentDate('5'), 'DD/MM/YYYY').format('YYYY-MM-DD')} />
                                        </>
                                        :
                                        <>
                                            <p>{calculatePaymentDate(formData?.payment_date)}</p>
                                            {/* <h5>{_t("You can postpone the payment until ")}{calculatePaymentDate('5')}</h5> */}

                                        </>
                                }
                                {/* min={minDate} max={maxDate} */}
                            </div>

                        </div>
                        {/* <button className="change-payment" onClick={e => setEditDate(true)}>{_t("Change Payment Date")}</button> */}
                    </div>

                    <div className="payment-detail-content">
                        <p> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.712 6.72339C13.3207 7.09299 12.85 7.27778 12.3 7.27778C11.7473 7.2803 11.2763 7.0955 10.887 6.72339C10.4977 6.35128 10.302 5.90645 10.3 5.38889C10.298 4.87134 10.4937 4.42682 10.887 4.05534C11.2803 3.68386 11.7513 3.49875 12.3 3.50001C12.8487 3.50127 13.3193 3.68638 13.712 4.05534C14.1047 4.4243 14.3007 4.86882 14.3 5.38889C14.2993 5.90897 14.1033 6.3538 13.712 6.72339ZM13.8 9.16667V20.5H10.8V9.16667H13.8Z" fill="#07569E"></path></svg> <span>{_t("Directly contact your landlord in case of mistakes")}.</span></p>
                    </div>
                    <button onClick={e => handleNextStep()} className="btn btngreen" >{("Confirm & Set Up Payment")}</button>
                    {/* <button class="uelz-button uelz-button-styles"
                        disabled={!haveValue(payment_date)}
                        id="uelz-button"
                        // className="btn btngreen"
                        style={{
                            padding: "14px 55px",
                            borderRadius: "12px",
                            fontWeight: 500,
                            fontSize: "15px",
                            lineHeight: "24px",
                            textAlign: "center",
                            background: "#A3EB64",
                            color: "#253837",
                            height: "unset",
                            width: "auto"
                        }}
                        data-service-name={constants.RENT}
                        // data-plan-name="monthlyRentTest1"
                        data-plan-name="dailyRentTest1"
                        data-plan-description={`monthly rent for awarded property`}
                        data-plan-amount="1"
                        // data-plan-amount={formData?.rent_amount}
                        data-plan-currency="EUR"
                        data-plan-type="Subscription"
                        // data-plan-frequency="month"
                        data-plan-frequency="day"
                        // data-plan-payment-day={payment_date}
                        data-type-subscription="variable"
                        data-future-charge-action="last_charge"
                        data-consume-units={amountDeduct}

                        data-customer-email={formData?.tenant_id?.email}
                        data-customer-cif={haveValue(formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.personal_number) ? formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.personal_number : haveValue(formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.document_number) ? formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.document_number : null}
                        data-name={formData?.tenant_id?.fname}
                        data-surname={formData?.tenant_id?.lname}
                        data-external-customer-id={formData?.tenant_id?._id}

                        onClick={checkElementOnInterval}
                    >
                        {_t("Confirm & Set Up Payment")}
                    </button> */}
                    {/* <button onClick={handleSubmitChanges}>DUMMY BUTTON</button> */}
                </div>
            </div >
        </>
    )
}


export default ReviewAssignProp