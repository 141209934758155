import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { sendSharedFeedback } from '../../services/sharedService';
import useTranslator from '../../hooks/useTranslator'

const Shared = () => {
    const { _t } = useTranslator();



    const queryParameters = new URLSearchParams(window.location.search);
    const userId = queryParameters.get("userid");
    console.log(userId);

    const [feedback, setFeedback] = useState({
        rating: 0,
        message: "",
    })
    const [loading, setLoading] = useState(false);
    const [screen, setScreen] = useState("feedback");

    useEffect(() => {

    })


    //full feedback sending flow
    const ratingChanged = (newRating) => {
        console.log(newRating);
        setFeedback({ ...feedback, rating: newRating });
    };

    const feedbackChange = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value });
    }


    const submitFeedback = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(feedback);
        sendSharedFeedback(userId, feedback)
            .then(res => {
                setLoading(false);
                toast.success(res?.data?.message);
                setFeedback({ rating: 0, message: "" });
                setScreen("thankyou")
                console.log(res);
            })
            .catch(err => {
                setLoading(false);
                //toast.error(err?.message);
                toast.error(err?.response?.data?.message);
                console.log(err);
            })

    }




    return (
        <>

            <div className="main-wapper height-auto bg-main-white">
                <div className="container">
                    {
                        loading && <>{_t("Please Wait")}...</>
                    }

                    <h2></h2>

                    {
                        screen == "feedback" &&
                        <>
                            <div className="shared-div">
                                <div className="Feedback-back">
                                    <h5 >{_t("Send Feedback")} </h5>

                                    <div className='feedback-star-div'>
                                        <ReactStars
                                            className={'feedback-star'}
                                            count={5}
                                            onChange={ratingChanged}
                                            size={40}
                                            activeColor="#F59F18"
                                            value={feedback?.rating || 0}
                                        />
                                    </div>
                                    <textarea id="" placeholder="Type your feedback here........" value={feedback?.message || ""} name="message" onChange={feedbackChange}></textarea>
                                    <button type="button" onClick={submitFeedback} className="btn-fill2 w-100  mt-3">{_t("Send")}</button>
                                </div>
                            </div>

                        </>
                    }

                    {
                        screen == "thankyou" &&
                        <>
                            <p>{_t("FeedBack Has Been Recorded You can now close this page")}</p>
                        </>
                    }



                </div>
            </div>
        </>
    )
}

export default Shared;