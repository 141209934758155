import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRouteTenant = ({ auth, component, ...rest }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("usertype") != "tenant") {
      navigate("/home");
    }
  }, []);

  return component;
};

export default ProtectedRouteTenant;
