//auth types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGGED_IN_TRUE = 'LOGGED_IN_TRUE';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_BANK_LOADING = 'TOGGLE_BANK_LOADING';
export const TOGGLE_ALGOAN_LOADING = 'TOGGLE_ALGOAN_LOADING';
export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SETOTP_PAGE = 'SETOTP_PAGE';
export const USER_VERIFIED = 'USER_VERIFIED'
export const USER_UNVERIFIED = 'USER_UNVERIFIED'
export const CARD_DATA = 'CARD_DATA'

//notification
export const NOTIFICATION_LOADED = 'NOTIFICATION_LOADED';
export const NOTIFICATION_LOAD_FAILED = 'NOTIFICATION_LOAD_FAILED'


// tenant types
export const DEMO_TENANT='DEMO_TENANT';
export const KYC_SUCCESS = 'KYC_SUCCESS';
export const ONFIDO_KYC_SUCCESS = 'ONFIDO_KYC_SUCCESS';
export const KYC_DATA_LOADED = 'KYC_DATA_LOADED';
export const KYC_DATA_FAILED = 'KYC_DATA_FAILED';
export const KYC_FAILED = 'KYC_FAILED';
export const ONFIDO_KYC_FAILED = 'ONFIDO_KYC_FAILED';
export const PAYMENT_SCORE_FAILED = 'PAYMENT_SCORE_FAILED';
export const PAYMENT_SCORE_SUCCESS = 'PAYMENT_SCORE_SUCCESS';
export const RENTED_PROPERTY_LOADED = 'RENTED_PROPERTY_LOADED';
export const PREVIOUS_RENTED_PROPERTY_LOADED = 'PREVIOUS_RENTED_PROPERTY_LOADED';
export const GET_KYC_DOCS = 'GET_KYC_DOCS'



//landlord types
export const LANDLORD_LOADED = 'LANDLORD_LOADED';
export const DEMO_LANDLORD='DEMO_LANDLORD';
export const PROPERTY_LOADED = 'PROPERTY_LOADED'
export const LOAD_PROPERTY = 'LOAD_PROPERTY';
export const SET_PROPERTY = 'SET_PROPERTY';
export const PROPERTY_ADDED = 'PROPERTY_ADDED';
export const PROPERTY_EDITED = 'PROPERTY_EDITED';
export const PROPERTY_DELETED = 'PROPERTY_DELETED';
export const PROPERTY_ERROR = 'PROPERTY_ERROR';
export const ERROR_RESOLVE = 'ERROR_RESOLVE';



//profile shared types
export const FULLSHARED_LOADED = 'FULLSHARED_LOADED';
export const BASICSHARED_LOADED = 'BASICSHARED_LOADED';
export const SHARED_PROFILE_FAILED_LOADING = 'SHARED_PROFILE_FAILED_LOADING';


