import { useContext } from "react";
import { haveValue } from "../../helper/common";
import useTranslator from "../../hooks/useTranslator"
import { BsExclamationCircleFill } from "react-icons/bs";
import LandLordContext from "../../context/landlord/LandLordContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";
import constants from "../../Utils/constants";



const ReviewAssignProperty = (props) => {

    const { _t } = useTranslator()
    const { property, sharedProfile, formData, handlePrevStep } = props;
    const landLordContext = useContext(LandLordContext)
    const { initiateAssignProperty } = landLordContext
    const authContext = useContext(AuthContext)
    const { setLoading } = authContext
    const navigate = useNavigate();


    const handleInitiate = async () => {
        setLoading(true);
        const sendData = { ...formData };
        sendData.tenant_id = sharedProfile?.tenantid?._id
        sendData.landlord_id = property?.landlord?._id
        sendData.monthly_sub_amount = parseFloat((Math.abs(formData?.deposit_amount - formData?.rent_amount) * constants.SUBSCRIPTION_PERCENTAGE / 12).toFixed(2));


        console.log(sendData);

        initiateAssignProperty(sendData)
            .then(res => {
                console.log(res)
                if (haveValue(res.status) && res.status == 'success') {
                    toast.success(_t('Notification sent to Tenant'))
                    navigate(`/payment/property/list?confirmation=true&assigned_prop=${res.notification.assigned_property_id}`)
                } else {
                    toast.error(_t('Error while assigning property!!!'))
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                toast.error(_t('Error while assigning property123123'))
                console.log(err)
            })
    }
    return (
        <>   <div className="review-form">
            <h2>{_t("Review")}</h2>
            <div className="review-form-body">
                <div className="body-inner-sec">
                    <div className="row">
                        <div className="col-sm-12">
                            <h6> {_t("Move-in address")} </h6>
                            <p>{`${haveValue(property?.landlord?.fname_companyName) ? property?.landlord?.fname_companyName : ''} ${haveValue(property?.landlord?.lname) ? property?.landlord?.lname : ''}`}</p>
                            <p>{`${property?.street} - ${property?.street_number}, ${property?.floor_number}-${property?.flat_number}`}</p>
                        </div>
                        <div className="col-sm-12">
                            <h6> {_t("Tenant")} </h6>
                            <p>{`${haveValue(sharedProfile?.tenantid?.fname) ? sharedProfile?.tenantid?.fname : ''} ${haveValue(sharedProfile?.tenantid?.lname) ? sharedProfile?.tenantid?.lname : ''}`}</p>
                            <p>Skor: {haveValue(sharedProfile?.tenantid?.paymentScores) ? sharedProfile?.tenantid?.paymentScores.creditScore.value : 0}</p>
                        </div>
                        <div className="col-sm-6">
                            <h6> {_t("Lease start date")} </h6>
                            <p>{formData?.payment_start}</p>
                        </div>
                        <div className="col-sm-6">
                            <h6> {_t("Lease end date")} </h6>
                            <p>{haveValue(formData?.lease_end) ? formData?.lease_end : _t('Open')}</p>
                        </div>
                        <div className="col-sm-12">
                            <h6> {_t("Is it a shared household")} </h6>
                            <p className="text-capitalize">{formData?.shared_household}</p>
                        </div>
                        <div className="col-sm-6">
                            <h6>{_t("Rental payment amount")}</h6>
                            <p>{formData?.rent_amount}</p>
                        </div>
                        <div className="col-sm-6">
                            <h6>{_t("Deposit amount")}</h6>
                            <p>{formData?.deposit_amount}</p>
                        </div>


                    </div>
                </div>
                <div className="payment-detail-content">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.712 6.72339C13.3207 7.09299 12.85 7.27778 12.3 7.27778C11.7473 7.2803 11.2763 7.0955 10.887 6.72339C10.4977 6.35128 10.302 5.90645 10.3 5.38889C10.298 4.87134 10.4937 4.42682 10.887 4.05534C11.2803 3.68386 11.7513 3.49875 12.3 3.50001C12.8487 3.50127 13.3193 3.68638 13.712 4.05534C14.1047 4.4243 14.3007 4.86882 14.3 5.38889C14.2993 5.90897 14.1033 6.3538 13.712 6.72339ZM13.8 9.16667V20.5H10.8V9.16667H13.8Z" fill="#07569E" />
                    </svg> <span>{_t("Tenant will add Payment Details")}.</span></p>
                </div>
                <div className="row w-100 justify-content-between">
                    <div className="col-lg-6 ">
                        <button className="tbtn-st" onClick={handlePrevStep}> {_t("Edit Rental Details")} </button>
                    </div>
                    <div className="col-lg-6 ">
                        <button className="btn btngreen w-100" onClick={handleInitiate}> {_t("Send to tenant")} </button>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default ReviewAssignProperty