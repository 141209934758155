import React, { useReducer, useState, useNavigate } from "react";
import axios from "axios";
import TenantContext from "./TenantContext";
import TenantReducer from "./TenantReducer";

import {
  BASICSHARED_LOADED,
  DEMO_TENANT,
  FULLSHARED_LOADED,
  KYC_DATA_FAILED,
  KYC_DATA_LOADED,
  KYC_FAILED,
  KYC_SUCCESS,
  PAYMENT_SCORE_FAILED,
  PAYMENT_SCORE_SUCCESS,
  PREVIOUS_RENTED_PROPERTY_LOADED,
  GET_KYC_DOCS,
  RENTED_PROPERTY_LOADED,
  TOGGLE_LOADING,
  ONFIDO_KYC_SUCCESS,
  ONFIDO_KYC_FAILED,
} from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";

const TenantState = (props) => {
  const { _t } = useTranslator();

  const initialState = {
    kycuserinfo: {},
    fullProfileShared: [],
    basicProfileShared: [],
    rentedProperties: [],
    previouslyRentedProperties: [],
    loadingTenant: "loading tenant",
    isLoading: false,
    isError: true,
    kyc_done: false,
    paymentInfoError: null,
    paymentInfo: null,
  };

  const [state, dispatch] = useReducer(TenantReducer, initialState);

  const demoFun = () => {
    toast.success(_t("tenant context fun is clicked"));
    dispatch({
      type: DEMO_TENANT,
      payload: "this is payload",
    });
  };

  const setLoading = (value) => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: value,
    });
  };

  const getKycInfo = async (tenantid, verificationid) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${tenantid}/${verificationid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let serverResponse = res;
        console.log(serverResponse);
        dispatch({
          type: KYC_DATA_LOADED,
          payload: serverResponse.data,
        });
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        dispatch({
          type: KYC_DATA_FAILED,
          payload: serverResponse.data,
        });
      });
  };

  const saveKycInfo = async (tenantid, value) => {
    console.log("Saving KYC Info ========>>");
    setLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${tenantid}`,
        value,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let serverResponse = res;
        console.log(serverResponse);
        dispatch({
          type: KYC_SUCCESS,
          payload: serverResponse,
        });
        //toast.success("Info Saved")
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: KYC_FAILED,
          payload: err,
        });
        toast.error(_t("Info Not Saved"));
      });
    setLoading(false);
  };

  // const aliceKyc = (tenantid, value) => {
  //     return new Promise(async (resolve, reject) => {
  //         await axios.post(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${tenantid}`, value, { headers: { "Accept-Language": localStorage.getItem('i18nextLng') } })
  //             .then(res => {
  //                 let serverResponse = res.data;
  //                 resolve(serverResponse);

  //             }).catch(err => {
  //                 reject(err);
  //             })
  //     })
  // }
  const onfidoKyc = (tenantid, value) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${tenantid}`,
          value,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          let serverResponse = res.data;
          resolve(serverResponse);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getKycDocs = async (tenantid) => {
    const docs = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/tenants/get-document/${tenantid}`,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          responseType: "blob",
        },
      }
    );
    return docs;
  };

  const getKycReport = async (tenantid) => {
    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/getkycstatus/${tenantid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            //toast.success("KYC Report Fetched")
            clearInterval(myInterval);
          }
        })
        .catch((err) => {
          console.log(err);
          //toast.error("Kyc Report Not Fetched")
          clearInterval(myInterval);
        });
    }, 12000);
  };

  const loadKycReport = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getkycreport/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        //toast.success("KYC Report Fetched"))
      })
      .catch((err) => {
        console.log(err);
        //toast.error("Kyc Report Not Fetched")
      });
  };

  const getAccountAndBalances = async (tenantid, code) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/finance/${tenantid}/${code}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let serverResponse = res;
        console.log(serverResponse);
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
      });
  };

  const calculatePaymentScore = async (code, tenantid) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getaccinfo?code=${code}&tenantid=${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        if (serverResponse.data.msg) {
          //toast.error(serverResponse.data.msg)
          dispatch({
            type: PAYMENT_SCORE_FAILED,
            payload: serverResponse.data.msg,
          });
        }
        if (serverResponse.data.score) {
          toast.success(_t("Payment score calculated return to home page"));
          dispatch({
            type: PAYMENT_SCORE_SUCCESS,
            payload: serverResponse.data.score,
          });
        }
      })
      .catch((err) => {
        let err_res = err;
        console.log(err_res);
        toast.error(err_res.message);
        dispatch({
          type: PAYMENT_SCORE_FAILED,
          payload: err_res,
        });
      });
  };

  const clearPaymentData = async (tenantid) => {
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        //toast.success(res.data.message);
        //toast.success("Previous Data cleared");
        console.log("previous data cleared");
      })
      .catch((err) => {
        let err_res = err;
        toast.error(err_res.message);
      });
  };

  const shareProfile = async (
    tenantid,
    landlordid,
    sharedprofileid,
    profiletype,
    propertyPayload
  ) => {
    console.log(tenantid, landlordid, profiletype, propertyPayload);
    const check_res = await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/send-profile/${tenantid}/${landlordid}/${sharedprofileid}?type=${profiletype}`,
        propertyPayload,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        // if (serverResponse.data.status == "success" && localStorage.getItem('usertype') == 'tenant') {
        //     toast.success(profiletype.toUpperCase() + " " + _t("Profile Shared"))
        // }
        if (localStorage.getItem("usertype") == "tenant") {
          loadSharedProfiles(tenantid, "full");
          // loadSharedProfiles(tenantid, "basic");
        }
        return true;
      })
      .catch((err) => {
        let err_res = err;
        console.log(err_res.response.data.error);
        toast.error(_t(`${err_res.response.data.error}`));
      });
    if (check_res) return true;
    else return false;
  };

  const loadSharedProfiles = async (tenantid, profiletype) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/myshared-profiles/${tenantid}?profiletype=${profiletype}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let serverResponse = res.data;
        if (profiletype == "full") {
          dispatch({
            type: FULLSHARED_LOADED,
            payload: serverResponse,
          });
        }
        if (profiletype == "basic") {
          dispatch({
            type: BASICSHARED_LOADED,
            payload: serverResponse,
          });
        }
      });
  };

  const myRentedProperties = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/properties/rentedproperties/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: RENTED_PROPERTY_LOADED,
          payload: res.data.properties,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const myPreviouslyRentedProperties = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/properties/previously-rented-properties/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: PREVIOUS_RENTED_PROPERTY_LOADED,
          payload: res.data.properties,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const endLease = async (propertyid, tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/properties/endlease/${propertyid}/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        toast.success(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveCard = async (userId, token) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/create-customer-save-card/${userId}/${token}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return result;
    } catch (err) {
      console.log("Some issue while adding card - ".err);
    }
  };

  const getAllSavedCard = async (userId) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/payment/get-customer-cards/${userId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return result;
    } catch (err) {
      console.log("Some issue while getting cards - ".err);
    }
  };

  const getUniversityListing = async (search, loadedOptions) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getUniversityListing/?search=${search}&offset=${loadedOptions.length}`
      );
      console.log(result);
      return result.data;
    } catch (err) {
      console.log("Error while fetching List", err);
    }
  };

  const linkGurantor = async (gurantor_id, tenant_id) => {
    const data = {
      gurantor_id,
      tenant_id,
    };
    const result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tenants/linkGurantor`,
      data,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
  };

  const guarantorDecline = async (gurantor_id, tenant_id) => {
    const data = {
      gurantor_id,
      tenant_id,
    };
    const result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tenants/guarantorDecline`,
      data,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
  };

  const removeGuarantor = async (
    tenant_id,
    gurantor_id,
    is_declined = false
  ) => {
    const data = {
      tenant_id,
      gurantor_id,
      is_declined,
    };
    const result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/tenants/removeGuarantor`,
      data,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    return result;
  };

  const updateDownloadedCheck = async (tenant_id) => {
    try {
      const data = {
        tenant_id,
      };
      const result = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/updateDownloaded`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFileData = async (filedata) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/uploadDocument`,
        filedata,
        {
          headers: {
            "Accept-Language": localStorage.getItem("i18nextLng"),
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res, "UPLOAD RES LAUNVHED");
      return res;
    } catch (err) {
      toast.error(_t("Error In Uploading File!"));
    }
  };

  const fetchTenantDocs = async (tenantId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getUploadedDocuments?tenantId=${tenantId}`,
        {},
        {
          headers: {
            "Accept-Language": localStorage.getItem("i18nextLng"),
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTenantDoc = async (docId) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/tenants/deleteDoc?docId=${docId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res;
    } catch (err) {
      toast.error(_t("Unable To Delete Document"));
      console.log(err);
      return false;
    }
  };

  const skipVerification = async (tenantId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/skipVerifications/${tenantId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res;
    } catch (err) {
      toast.error(_t("Unable to process request"));
      console.log(err);
      return false;
    }
  };

  const getPlaidLinkToken = async () => {
    try {
      const tenantId = localStorage.getItem("saveduserid");
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/initiatePlaid`,
        { tenantId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while initialising Plaid Token"));
      console.log(err);
      return false;
    }
  };

  const sendPublicToken = async (public_token) => {
    try {
      const tenantId = localStorage.getItem("saveduserid");
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/setPublicToken`,
        { public_token, tenantId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while initialising Plaid Token"));
      console.log(err);
      return false;
    }
  };

  const removeSharedProfile = async (sharedProfileId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/removeSharedProfile`,
        { sharedProfileId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while removing Shared Profile"));
      console.log(err);
      return false;
    }
  };

  const acceptAssignedProp = async (formData, assignedPropId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/accept/assigned/property/${assignedPropId}`,
        formData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while removing Shared Profile"));
      console.log(err);
      return false;
    }
  };

  const checkEmailInDeposit = async (email) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/check/email/deposit`,
        { email },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while fetching email deposit"));
      console.log(err);
      return false;
    }
  };

  const joinWaitlist = async (email) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/joinWaitlist`,
        { email },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while joining waitlist"));
      console.log(err);
      return false;
    }
  };

  const getDocuSignURL = async (formData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/docuSign`,
        formData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error while generating link!"));
      console.log(err);
      return false;
    }
  };

  const checkUelzStatus = async (assignedPropId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/check/uelz/status/${assignedPropId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      // toast.error(_t("Error fetching Uelz status!"));
      console.log(err);
      return err;
    }
  };

  const uploadDepositProof = async (filedata) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/upload/deposit-proof`,
        filedata,
        {
          headers: {
            "Accept-Language": localStorage.getItem("i18nextLng"),
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res, "UPLOAD RES LAUNVHED");
      return res;
    } catch (err) {
      toast.error(_t("Error In Uploading File!"));
    }
  };

  const createUelzLedger = async (assignedPropertyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/uelz/ledger`,
        { assignedPropertyId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error in initiating payment!"));
      console.log(err);
    }
  };

  const createTransactionLedger = async (formData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/uelz/ledger`,
        formData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      toast.error(_t("Error in initiating payment!"));
      console.log(err);
    }
  };

  const initiateInespay = async (assignedPropertyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/inititateInespay`,
        { assignedPropertyId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      res.data.continue = true;
      return res.data;
    } catch (err) {
      toast.error(_t("Error in inititating payment!"));
      console.log(err);
      return { continue: false };
    }
  };

  const checkInespayStatus = async (assignedPropertyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/inespayStatus`,
        { assignedPropertyId },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      res.data.continue = true;
      return res.data;
    } catch (err) {
      toast.error(_t("Error in inititating payment!"));
      console.log(err);
      return { continue: false };
    }
  };

  const updateKyc = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/update/kyc`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error(_t("Error in updating status!"));
    }
  };

  const rentPayment = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/rent/payment`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error(_t("Error while generating session!"));
    }
  };

  const rePayRent = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/repay/rent`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error(_t("Error while generating session!"));
    }
  };

  const updateTenant = async (data) => {
    try {
      const temp = {
        tenant_id: localStorage.getItem("saveduserid"),
        data,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/update/tenant/fields`,
        temp,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err, "Error in updating tenant fields");
    }
  };

  const getRentalInfo = async (tenant_id = null) => {
    try {
      if (!haveValue(tenant_id)) {
        tenant_id = localStorage.getItem("saveduserid");
      }
      const temp = {
        tenant_id,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/rental/info`,
        temp,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err, "Error in updating tenant fields");
    }
  };

  const ledgerData = async (tenant_id = null) => {
    try {
      if (!haveValue(tenant_id)) {
        tenant_id = localStorage.getItem("saveduserid");
      }
      const temp = {
        tenant_id,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/ledger/data`,
        temp,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err, "Error in updating tenant fields");
    }
  };

  const initiateBankFlip = async (type) => {
    try {
      const temp = {
        tenant_id: localStorage.getItem("saveduserid"),
        type: type,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/initiate/bankflip`,
        temp,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err, "Error while initiating BankFlip");
    }
  };

  const fetchWaitingData = async (waitingId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/waiting/data/${waitingId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return res.data;
    } catch (err) {
      console.log(err, "Error while initiating BankFlip");
    }
  };

  return (
    <TenantContext.Provider
      value={{
        kycuserinfo: state.kycuserinfo,
        fullProfileShared: state.fullProfileShared,
        basicProfileShared: state.basicProfileShared,
        rentedProperties: state.rentedProperties,
        previouslyRentedProperties: state.previouslyRentedProperties,
        loadingTenant: state.loadingTenant,
        isLoading: state.isLoading,
        isError: state.isError,
        kyc_done: state.kyc_done,
        paymentInfo: state.paymentInfo,
        paymentInfoError: state.paymentInfoError,
        demoFun,
        saveKycInfo,
        // aliceKyc,
        onfidoKyc,
        getKycInfo,
        getKycReport,
        loadKycReport,
        getAccountAndBalances,
        getKycDocs,
        calculatePaymentScore,
        clearPaymentData,
        shareProfile,
        setLoading,
        loadSharedProfiles,
        myRentedProperties,
        myPreviouslyRentedProperties,
        endLease,
        saveCard,
        getAllSavedCard,
        getUniversityListing,
        linkGurantor,
        removeGuarantor,
        updateDownloadedCheck,
        uploadFileData,
        fetchTenantDocs,
        deleteTenantDoc,
        skipVerification,
        getPlaidLinkToken,
        sendPublicToken,
        guarantorDecline,
        removeSharedProfile,
        acceptAssignedProp,
        checkEmailInDeposit,
        joinWaitlist,
        getDocuSignURL,
        checkUelzStatus,
        uploadDepositProof,
        createUelzLedger,
        initiateInespay,
        checkInespayStatus,
        updateKyc,
        rentPayment,
        updateTenant,
        getRentalInfo,
        rePayRent,
        ledgerData,
        createTransactionLedger,
        initiateBankFlip,
        fetchWaitingData
      }}
    >
      {props.children}
    </TenantContext.Provider>
  );
};

export default TenantState;
