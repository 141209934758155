import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import constants from "../../../Utils/constants";
import { haveValue } from "../../../helper/common";
import TenantContext from "../../../context/tenant/TenantContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { VscEye } from "react-icons/vsc";
import Loader from "../../../components/Spinner";

const TenantProvideDocument = () => {
  const { _t } = useTranslator();
  const queryParameters = new URLSearchParams(window.location.search);
  const modelType = queryParameters.get("type");
  const waitingId = queryParameters.get("waiting");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const {
    uploadFileData,
    fetchTenantDocs,
    updateTenant,
    initiateBankFlip,
    fetchWaitingData,
  } = tenantContext;
  const { user } = authContext;
  console.log(waitingId, "waitingIdwaitingId");
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [tenantDocsData, setTenantDocsData] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isBankflip, setIsBankflip] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [revisit, setRevisit] = useState(false);
  const [fromClave, setFromClave] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  });
  const [claveModels, setClaveModels] = useState({
    AEAT_100_RENTA: false,
    AEAT_130_IRPF_DIRECTA: false,
    AEAT_303_IVA_AUTOLIQUIDACION: false,
    SEG_SOCIAL_VIDA_LABORAL: false,
  });

  useEffect(() => {
    fetchFileData();
    if (haveValue(modelType) && modelType == "manual") {
      setCurrentStep(1);
      setFromClave(false);
    }
    if (haveValue(waitingId)) {
      setLocalLoading(true);
      const temp_interval = setInterval(() => {
        fetchWaitingData(waitingId)
          .then((res) => {
            if (res.status == constants.COMPLETED) {
              clearInterval(temp_interval);
              fetchFileData();
              setLocalLoading(false);
            }
          })
          .catch((err) => {
            setLocalLoading(false);
            clearInterval(temp_interval);
            console.log(err);
          });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (user?.docs_uploaded) {
      setRevisit(true);
    }
    if (user?.employment_status == constants.employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
      }));
    }
    if (user?.employment_status == constants.self_employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
        AEAT_130_IRPF_DIRECTA: true,
      }));
    }
    if (
      user?.employment_status == constants.self_employed &&
      user?.is_freelancer == true
    ) {
      setClaveModels((prev) => ({
        ...prev,
        AEAT_303_IVA_AUTOLIQUIDACION: true,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (tenantDocsData.length > 2) {
      console.log("CONDITION MET HERE");
      setSliderSettings({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <BsFillArrowRightSquareFill />,
        prevArrow: <BsFillArrowLeftSquareFill />,
      });
    }
  }, [tenantDocsData]);

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
    if (
      e.target.value == constants.income_tax_return ||
      e.target.value == constants.fractioned_tax_return ||
      e.target.value == constants.quarterly_vat ||
      e.target.value == constants.previous_working_life_certificate
    ) {
      // checkBankflip(e.target.value);
      setIsBankflip(true);
    } else {
      setIsBankflip(false);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("tenantDocs", files[i]);
    }
    formData.append("fileType", fileType);
    setFileData(formData);
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData);

      setFileData(null);
      setFileType("");
      await fetchFileData();
      handleClose();
      setUploadLoading(false);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  const addRewardPoints = () => {
    const data = {
      reward_points:
        (user?.reward_points || 0) + +constants.DOCS_COMPLETE_POINTS,
    };
    updateTenant(data);
  };

  const fetchFileData = async () => {
    console.log("fetchFileData called");
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleSubmitDocuments = async () => {
    if (!user?.docs_uploaded) {
      addRewardPoints();
    }
    updateTenant({ docs_uploaded: true })
      .then((res) => {
        setCurrentStep(2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("Continue To Verify Id")}
        </button>
      );
    } else if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("Continue To Connect Bank")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleClaveChange = (e) => {
    const event = e.target;
    setClaveModels((prev) => ({ ...prev, [event.value]: event.checked }));
  };

  const checkBankflip = () => {
    // var temp = "";
    // const type = fileType;
    // if (type == constants.income_tax_return) {
    //   temp = "AEAT_100_RENTA";
    // } else if (type == constants.fractioned_tax_return) {
    //   temp = "AEAT_130_IRPF_DIRECTA";
    // } else if (type == constants.quarterly_vat) {
    //   temp = "AEAT_303_IVA_AUTOLIQUIDACION";
    // } else if (type == constants.previous_working_life_certificate) {
    //   temp = "SEG_SOCIAL_VIDA_LABORAL";
    // }

    if (
      !claveModels.AEAT_100_RENTA &&
      !claveModels.AEAT_130_IRPF_DIRECTA &&
      !claveModels.AEAT_303_IVA_AUTOLIQUIDACION &&
      !claveModels.SEG_SOCIAL_VIDA_LABORAL
    ) {
      toast.error(_t("Select atleast 1 document"));
      return;
    }
    initiateBankFlip(claveModels)
      .then((res) => {
        window.location.replace(res.success_res.widgetLink);
        console.log(res, "BANK FLIP RESPONSE");
      })
      .catch((err) => {
        console.log(err);
        toast.error(_t("Error while inititating Docusign!"));
      });
  };

  return (
    <>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={navigateHome}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837"></circle>
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  ></path>
                </svg>
                <span>{_t("Provide Documents")}</span>
              </h3>
            </div>
            <div className="logobarwrapper">
              <Container className="progress-bar">
                <ul className="progress-ul">
                  <li
                    className={`progress-li ${
                      currentStep == 0 ? "current-progress" : ""
                    }  ${currentStep > 0 ? "current-completed" : ""}`}
                  ></li>
                  <li
                    className={`progress-li ${
                      currentStep == 1 ? "current-progress" : ""
                    }  ${currentStep > 1 ? "current-completed" : ""}`}
                  ></li>
                </ul>
              </Container>
            </div>
            <div className="steps-wrapper">
              <h3 className="step-name">
                {_t("Select an option to continue")}
              </h3>
              <div className="resend-text steps-wrapper">
                <div className="">
                  <div className="icon-text-box">
                    <img src="/images/clavePin.svg" alt="" />
                    <div className="text-box">
                      <h4 className="box-heading" style={{textTransform:"none"}}>
                        {_t("Option 1: Get From Cl@ve PIN")}
                      </h4>
                      <p className="box-desc" style={{textTransform:"none"}}>
                        {_t(
                          "Provide your documents to skor via Cl@ve PIN with ease and efficiency"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="icon-text-box">
                    <img src="/images/uploadIcon.svg" alt="" />
                    <div className="text-box">
                      <h4 className="box-heading">
                        {_t("Option 2: Upload manually")}
                      </h4>
                      <p className="box-desc" style={{textTransform:"none"}}>
                        {_t(
                          "If you don't have a Cl@ve PIN, you can upload all your documents manually"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btnorange step-btn"
                  onClick={(e) => {
                    setFromClave(true);
                    setCurrentStep((prev) => prev + 1);
                  }}
                  style={{textTransform:"none"}}
                >
                  {_t("Get From Cl@ve PIN")}
                </button>
                <button
                  className="btn btnoutline bg-none"
                  onClick={(e) => {
                    setFromClave(false);
                    setCurrentStep((prev) => prev + 1);
                  }}
                >
                  {_t("Upload Manually")}
                </button>
                <div className="document_preview_div">
                  <h5>{_t("Your Documents")}</h5>
                  {localLoading ? (
                    <Loader
                      waiting_text={
                        "Fetching your documents. It may take some time."
                      }
                    />
                  ) : tenantDocsData.length == 0 ? (
                    _t("No documents uploaded")
                  ) : (
                    <Row className="card-listing">
                      <Slider {...sliderSettings}>
                        {tenantDocsData?.map((eachData, index) => (
                          <Col md={6} key={eachData?._id}>
                            <Card>
                              <div className="videoframe">
                                <iframe
                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                                  scrolling="no"
                                />
                              </div>
                              <Card.Body>
                                <div className="listing-head">
                                  <div className="head-block">
                                    <div
                                      className="leftbartext"
                                      style={{ maxWidth: "85%" }}
                                    >
                                      <h5 style={{ paddingTop: "0px" }}>
                                        {eachData?.type}
                                      </h5>
                                      <p>
                                        {eachData?.file_name
                                          .split("-")
                                          .slice(1)
                                          .join("-")}
                                      </p>
                                    </div>
                                    <VscEye
                                      onClick={(e) =>
                                        handlePreviewDocument(
                                          `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Slider>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a
                    href={undefined}
                    onClick={(e) => setCurrentStep((prev) => prev - 1)}
                  >
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837"></circle>
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  ></path>
                </svg>
                <span>{_t("Provide Documents")}</span>
              </h3>
            </div>
            <div className="logobarwrapper">
              <Container className="progress-bar">
                <ul className="progress-ul">
                  <li
                    className={`progress-li ${
                      currentStep == 0 ? "current-progress" : ""
                    }  ${currentStep > 0 ? "current-completed" : ""}`}
                  ></li>
                  <li
                    className={`progress-li ${
                      currentStep == 1 ? "current-progress" : ""
                    }  ${currentStep > 1 ? "current-completed" : ""}`}
                  ></li>
                </ul>
              </Container>
            </div>
            {fromClave ? (
              <div className="steps-wrapper">
                <h3 className="step-name" style={{textTransform:"none"}}>
                  {_t("Select documents to get from Cl@ve PIN")}
                </h3>
                <div className="align-items-center clave-checboxes">
                  {(user?.employment_status == constants.employed ||
                    user?.employment_status == constants.self_employed) && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.AEAT_100_RENTA}
                        value={"AEAT_100_RENTA"}
                      />
                      <div class="boxes">
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("Last annual Tax Report (Model 100)")}
                      </div>
                    </label>
                  )}
                  {user?.employment_status == constants.self_employed && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.AEAT_130_IRPF_DIRECTA}
                        value={"AEAT_130_IRPF_DIRECTA"}
                      />
                      <div class="boxes">
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("Last Fractionated Tax Report (Model 130)")}
                      </div>
                    </label>
                  )}
                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer == true && (
                      <label class="customradiobar forminput">
                        <input
                          type="checkbox"
                          name="have_sharer"
                          className="clave_checkbox"
                          onClick={handleClaveChange}
                          checked={claveModels.AEAT_303_IVA_AUTOLIQUIDACION}
                          value={"AEAT_303_IVA_AUTOLIQUIDACION"}
                        />
                        <div class="boxes">
                          <span className="empty_checkbox"></span>
                          <img src="/images/greenCheck.svg" alt="" />
                          {_t("Last quarterly VAT tax (Form 303)")}
                        </div>
                      </label>
                    )}
                  {(user?.employment_status == constants.employed ||
                    user?.employment_status == constants.self_employed) && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.SEG_SOCIAL_VIDA_LABORAL}
                        value={"SEG_SOCIAL_VIDA_LABORAL"}
                      />
                      <div class="boxes">
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("Working life certificate")}
                      </div>
                    </label>
                  )}
                </div>
                {/* {!revisit && ( */}
                <button
                  className="btn btnorange step-btn"
                  onClick={checkBankflip}
                >
                  {_t("Next")}
                </button>
                {/* )} */}
              </div>
            ) : (
              <div className="steps-wrapper">
                <h3 className="step-name">
                  {/* {revisit
                  ? _t("Provide additional documents")
                  : _t(
                      "Verify your documentation to complete your Tenant Profile"
                    )} */}
                  {_t("Upload documents below")}
                </h3>
                <div className="align-items-center">
                  <Form.Select
                    size="lg"
                    onChange={(e) => {
                      handleSelectChange(e);
                    }}
                    value={fileType}
                    className="form-control"
                  >
                    <option value="">{_t("Select")}</option>

                    {user?.employment_status == constants.student && (
                      <>
                        <option value={constants.ie_student}>
                          {_t("Student registration contract")}
                        </option>
                        <option value={constants.university_acceptance_letter}>
                          {_t("University Acceptance Letter")}
                        </option>
                      </>
                    )}

                    {user?.employment_status == constants.employed && (
                      <>
                        <option value={constants.employment_contract}>
                          {_t("Employment Contract")}
                        </option>
                        <option value={constants.payslips}>
                          {_t("Last 3 payslips")}
                        </option>
                        <option
                          value={constants.previous_working_life_certificate}
                        >
                          {_t("Working life certificate")}
                        </option>
                        <option value={constants.income_tax_return}>
                          {_t("Last annual Tax Report (Model 100)")}
                        </option>
                        <option value={constants.deduction_certificate}>
                          {_t("Deduction certificate")}
                        </option>
                        <option
                          value={constants.public_appointment_certificate}
                        >
                          {_t(
                            "Public appointment certificate (only if public employee)"
                          )}
                        </option>
                      </>
                    )}

                    {user?.employment_status == constants.self_employed &&
                      user?.is_freelancer == true && (
                        <>
                          <option value={constants.fractioned_tax_return}>
                            {_t("Last Fractionated Tax Report (Model 130)")}
                          </option>
                          <option value={constants.income_tax_return}>
                            {_t("Last annual Tax Report (Model 100)")}
                          </option>
                          <option
                            value={constants.previous_working_life_certificate}
                          >
                            {_t("Working life certificate")}
                          </option>
                          <option value={constants.quarterly_vat}>
                            {_t("Last quarterly VAT tax (Form 303)")}
                          </option>
                        </>
                      )}

                    {user?.employment_status == constants.self_employed &&
                      user?.is_freelancer != true && (
                        <>
                          <option
                            value={constants.previous_working_life_certificate}
                          >
                            {_t("Working life certificate")}
                          </option>
                          <option value={constants.income_tax_return}>
                            {_t("Last annual Tax Report (Model 100)")}
                          </option>
                          <option value={constants.fractioned_tax_return}>
                            {_t("Last Fractionated Tax Report (Model 130)")}
                          </option>
                          <option value={constants.corporate_tax_return}>
                            {_t("Last year's Corporate Tax Return")}
                          </option>
                          <option value={constants.corporate_balance_sheet}>
                            {_t("Last year's Corporate balance sheet")}
                          </option>
                          <option value={constants.corporate_PL}>
                            {_t("Last year's Corporate P&L")}
                          </option>
                        </>
                      )}
                    {user?.employment_status == constants.retired && (
                      <>
                        <option value={constants.pension_certificate}>
                          {_t("Pension certificate")}
                        </option>
                        <option value={constants.last_revalorization_letter}>
                          {_t("Last revalorization letter")}
                        </option>
                      </>
                    )}
                    {
                      <option value="Other Documents">
                        {_t("Other Documents")}
                      </option>
                    }
                  </Form.Select>
                  <div className="d-flex align-items-center gap-2">
                    {/* {isBankflip && (
                      <>
                        {" "}
                        <button
                          className="btn btnorange step-btn btn-100"
                          onClick={() => {
                            checkBankflip();
                          }}
                          disabled={haveValue(fileType) ? false : true}
                        >
                          {_t("Get From Clave Pin")}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="16"
                            viewBox="0 0 9 16"
                            fill="none"
                          >
                            <path
                              d="M1 1L7.54748 7.00483C7.69083 7.13486 7.80465 7.2897 7.88233 7.46036C7.96 7.63103 8 7.81414 8 7.99909C8 8.18403 7.96 8.36714 7.88233 8.53781C7.80465 8.70848 7.69084 8.86331 7.54748 8.99334L1 15"
                              stroke="#253837"
                              stroke-width="1.4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <p>{_t("or")}</p>
                      </>
                    )} */}
                    <button
                      className="btn step-go-home-btn btn-100"
                      onClick={() => {
                        handleShow();
                      }}
                      disabled={haveValue(fileType) ? false : true}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                      >
                        <path
                          d="M0 18H16V15.8824H0V18ZM0 7.41177H4.57143V13.7647H11.4286V7.41177H16L8 0L0 7.41177Z"
                          fill="#253837"
                        />
                      </svg>
                      {_t("Upload Here")}
                    </button>
                  </div>
                </div>
                {/* {!revisit && <p>{tenantDocsData.length}/3</p>} */}
                <Row className="card-listing">
                  <Slider {...sliderSettings}>
                    {tenantDocsData?.map((eachData, index) => (
                      <Col md={6} key={eachData?._id}>
                        <Card>
                          <div className="videoframe">
                            <iframe
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                              scrolling="no"
                            />
                          </div>
                          <Card.Body>
                            <div className="listing-head">
                              <div className="head-block">
                                <div className="leftbartext" style={{ maxWidth: "85%" }}>
                                  <h5 style={{ paddingTop: "0px" }}>{eachData?.type}</h5>
                                  <p style={{ overflow: "hidden" }}>
                                    {eachData?.file_name
                                      .split("-")
                                      .slice(1)
                                      .join("-")}
                                  </p>
                                </div>
                                <VscEye
                                  onClick={(e) =>
                                    handlePreviewDocument(
                                      `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Slider>
                </Row>
                {!revisit && (
                  <button
                    className="btn btnorange step-btn"
                    disabled={tenantDocsData.length < 1}
                    onClick={handleSubmitDocuments}
                  >
                    {_t("Submit")}
                  </button>
                )}
              </div>
            )}
            <Modal
              className="modal-uploadpdf upload-doc"
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">{_t("Select Document")}</h5>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formFile" className="formcontrolbar">
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    multiple={
                      fileType != constants.income_tax_return &&
                      fileType != constants.ie_student
                    }
                    accept="application/pdf"
                  />
                  <Button
                    className="btn-green"
                    onClick={handleFileUpload}
                    disabled={
                      haveValue(fileData) && !uploadLoading ? false : true
                    }
                  >
                    {uploadLoading ? _t("Please Wait") : _t("Upload")}
                  </Button>
                </Form.Group>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
      {currentStep == 2 && (
        <>
          <div className="step-form-main-wrapper">
            <div class="d-flex justify-content-center align-items-center mb-5">
              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837"></circle>
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  ></path>
                </svg>
                <span>{_t("Provide Documents")}</span>
              </h3>
            </div>
            <div className="step-complete-reward steps-wrapper">
              <div className="rewards-points">
                <span className="points">50</span>
                <p>{_t("Reward Points")}</p>
              </div>
              <p className="greeting-text">{_t("Congratulations")}</p>
              <p className="points-info">
                {_t(
                  "You’ve uploaded your documentation to your profile info and have been rewarded with 50 points"
                )}
              </p>
              {checkNextButton()}
              <button className="step-go-home-btn" onClick={navigateHome}>
                {_t("Go Back home")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantProvideDocument;
