import {
    BASICSHARED_LOADED,
DEMO_TENANT, FULLSHARED_LOADED, KYC_DATA_FAILED, KYC_DATA_LOADED, KYC_FAILED, KYC_SUCCESS,ONFIDO_KYC_SUCCESS, PAYMENT_SCORE_FAILED, 
PAYMENT_SCORE_SUCCESS, PREVIOUS_RENTED_PROPERTY_LOADED, RENTED_PROPERTY_LOADED, SHARED_PROFILE_FAILED_LOADING, TOGGLE_LOADING
} from '../types';

export default (state,action) =>{
    switch(action.type){

        case DEMO_TENANT:
            return{
                ...state,
                loadingTenant:action.payload
        }
        case TOGGLE_LOADING:
            return{
                ...state,
                isLoading:action.payload
            }

        
        case KYC_SUCCESS:
            return{
                ...state,
                isLoading:false,
                kyc_done:true,
        }

        case ONFIDO_KYC_SUCCESS:
            return{
                ...state,
                kycuserinfo:action.payload
            }

        case KYC_FAILED:
            return{
                ...state,
                isLoading:false,
                kyc_done:false,
        }
        case KYC_DATA_LOADED:
            return{
                ...state,
                isLoading:false,
        }
        case KYC_DATA_FAILED:
            return{
                ...state,
                isLoading:false,
        }
        case PAYMENT_SCORE_SUCCESS:
            return{
                ...state,
                paymentInfo:action.payload,
                paymentInfoError:null,
                isLoading:false,
        }
        case PAYMENT_SCORE_FAILED:
            return{
                ...state,
                paymentInfo:null,
                paymentInfoError:action.payload,
                isLoading:false,
        }

        case FULLSHARED_LOADED:
            return{
                ...state,
                fullProfileShared:action.payload

            }

        case BASICSHARED_LOADED:
            return{
                ...state,
                basicProfileShared:action.payload
    
            }
        case SHARED_PROFILE_FAILED_LOADING:
            return{
                ...state,
            }

        case RENTED_PROPERTY_LOADED:
            return {
                ...state,
                rentedProperties:action.payload
            }

        case PREVIOUS_RENTED_PROPERTY_LOADED:
            return {
                ...state,
                previouslyRentedProperties: action.payload
            }



        default:
            return state
    }
}