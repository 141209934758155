import React, { Fragment, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AuthContext from "../context/auth/AuthContext";
import TenantContext from "../context/tenant/TenantContext";
import LandLordContext from "../context/landlord/LandLordContext";

import Finance from "./tenant/finance/Finance";
import Property from "./landlord/property/Property";
import TenantHome from "./tenant/TenantHome";
import useTranslator from "../hooks/useTranslator";
import DigitalVerification from "../components/DigitalVerification";
import { useCookies } from "react-cookie";
import { haveValue } from "../helper/common";
import ScoreWaitModal from "../components/ScoreWaitModal";

const Home = () => {
  const { _t } = useTranslator();
  let location = useLocation();

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const {
    isLoggedIn,
    logout,
    user,
    loadMyNotifications,
    loadUserTenant,
    algoanLoading,
  } = authContext;
  const userType = localStorage.getItem("usertype");

  useEffect(() => {
    if (user && !user?.has_profile) {
      if (userType == "tenant" && location.pathname == "/") {
        navigate("/tenant");
      } else {
        navigate("/home");
      }
      // navigate("/tenant");
    } else {
      let userid = localStorage.getItem("saveduserid");
      if (user?._id) loadMyNotifications(userid, userType);
    }
    if (userType == "tenant" && location.pathname == "/") {
      navigate("/tenant");
    } else {
      navigate("/home");
    }

    if (userType == "tenant") {
      if (haveValue(userType)) {
        var timer = setInterval(() => {
          loadUserTenant(userType);
        }, 10000);
      }
    }

    return () => {
      if (haveValue(timer)) clearInterval(timer);
    };
  }, []);

  if (userType === "landlord" || userType === "admin") {
    return (
      <>
        {user?.has_profile ? (
          <Property />
        ) : (
          <p>
            {_t("Profile is not completed please complete the profile")}{" "}
            <Link to="/profile" className="btn btn-danger">
              {_t("Complete My Profile")}
            </Link>
          </p>
        )}
      </>
    );
  }

  if (userType === "tenant") {
    return (
      <>
        {/* <div className='margin-div'></div> */}
        {algoanLoading && <ScoreWaitModal waitBank={algoanLoading} />}
        {user?.analysisCompleted == true && <TenantHome />}

        {user?.analysisCompleted != true && (
          <>
            <div className="shadow mainwraper">
              <DigitalVerification />
            </div>
          </>
        )}
      </>
    );
  }
};
export default Home;
