import React, { Fragment } from 'react';

import offercompanylogoicon from '../../../assets/img/service-item.png';
import useTranslator from '../../../hooks/useTranslator'

const OfferItem = ({ offer }) => {
    const { _t } = useTranslator();

    return (
        <>
            {/* 
            <div className="card">
                <div className="card-body">
                    <h2>{offer?.offerCoupon}</h2>
                    <h2>{offer?.title}</h2>
                    <h3>{offer?.subtitle}</h3>
                </div>
            </div> */}


            <div className="buy-section">
                <div className="inner-content">
                    <div>
                        <span  className='card-items-style' style={{ padding: "3px", fontWeight: "bold", color: "green", border: "1px dotted green", backgroundColor: "lightgreen", borderRadius: "5px" }}>{offer?.offerCoupon}</span>
                        <h5>{offer?.title}</h5>
                        <p>{offer?.subtitle}</p>
                    </div>
                    <div>
                        <a href="#"><img width={100} height={100} src={`${process.env.REACT_APP_SERVER_URL}/${offer?.photo}`} alt="" /></a>
                    </div>
                </div>
                <div className="buy-info">
                    <a href="#">{_t("Buy Now")}</a>
                    <h4>{_t("Endesa")}</h4>
                </div>
            </div>



        </>
    )
}

export default OfferItem;