import { Grid } from  'react-loader-spinner'
import useTranslator from '../hooks/useTranslator';

const LoaderBank = ({type}) =>{
    const { _t } = useTranslator();

    return(
        <div className='global-loader'>
            <div className="child-loader">
                <Grid
                    height="80"
                    width="80"
                    color="#253837"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
                <p style={{color:'#253837'}}>{_t("Fetching Details From Bank")}...</p>
            </div>
        </div>
        
    )
};

export default LoaderBank;