import { Modal } from "react-bootstrap"
import useTranslator from "../hooks/useTranslator"
import { useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import AuthContext from "../context/auth/AuthContext"
import { haveValue } from "../helper/common"
import TenantContext from "../context/tenant/TenantContext"



const AcceptGuarantor = (props) => {

    const { _t } = useTranslator()

    const { showConfirmGuarantor, setShowConfirmGuarantor } = props

    const [guarantorFor, setGuarantorFor] = useState({})
    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])

    const authContext = useContext(AuthContext)
    const { getUserDetails, loadUser } = authContext;

    const tenantContext = useContext(TenantContext)
    const { linkGurantor, removeGuarantor } = tenantContext

    useEffect(() => {

        const callFunction = async () => {
            const usertype = localStorage.getItem('usertype')
            const userid = cookies.gurantorFor;
            console.log(userid, "COOKIR VALUE")
            const data = await getUserDetails(usertype, userid);
            setGuarantorFor(data);
            console.log("User data is here to check", data)
        }

        callFunction()

    }, [])

    const handleAccept = (e) => {
        e.preventDefault()
        const userId = localStorage.getItem('saveduserid')
        linkGurantor(userId, guarantorFor?._id)
            .then(res => {
                loadUser('tenant')
                removeCookie('gurantorFor', { maxAge: 1800 })
                setShowConfirmGuarantor(false)
            })
            .catch(err => {
                console.log(err)
            })
        console.log("Accepted")
    }

    const handleReject = async () => {
        const userId = localStorage.getItem('saveduserid')
        setShowConfirmGuarantor(false)
        await removeGuarantor(guarantorFor?._id, userId, true)
        removeCookie('gurantorFor', { maxAge: 1800 })
    }

    return (
        <>
            <Modal show={showConfirmGuarantor} centered className="newmodalui asguarantor modal-lg">
                <Modal.Header>
                    <Modal.Title>{`${guarantorFor?.fname} ${guarantorFor?.lname}`} {_t("Want To Set You As A Guarantor")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAccept}>
                        <div className="auth-form">
                            {/* <div className="formtile">
                                <h4>{`${guarantorFor?.fname} ${guarantorFor?.lname}`}<span> {_t("Basic Profile")}</span></h4>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-6 col-md-12 mb-4">
                                    <label className="forminput">{_t("Name")}:</label>
                                    <p>{guarantorFor?.fname} {guarantorFor?.lname}</p>
                                </div>
                                <div className="col-lg-6 col-md-12 mb-4">
                                    <label className="forminput">{_t("Nationality")}:</label>
                                    <p>{guarantorFor?.nationality}</p>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="forminput">{_t("Phone Number")}:</label>
                                    <p>{haveValue(guarantorFor?.phone_number) ? guarantorFor?.phone_number : _t("No Info")}</p>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="forminput">{_t("Email")}:</label>
                                    <p>{guarantorFor?.email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="btngroup row100 gridbar">
                            <button className="btncancle" type="button" onClick={handleReject}>
                                <svg className="me-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8134 1.08759C13.8726 1.02843 13.9195 0.958196 13.9515 0.8809C13.9835 0.803604 14 0.720759 14 0.637094C14 0.55343 13.9835 0.470585 13.9515 0.393289C13.9195 0.315993 13.8726 0.24576 13.8134 0.1866C13.7542 0.127441 13.684 0.080513 13.6067 0.048496C13.5294 0.016479 13.4466 0 13.3629 0C13.2792 0 13.1964 0.016479 13.1191 0.048496C13.0418 0.080513 12.9716 0.127441 12.9124 0.1866L7 6.10029L1.08759 0.1866C1.02843 0.127441 0.958196 0.080513 0.8809 0.048496C0.803604 0.016479 0.720759 6.23348e-10 0.637094 0C0.55343 -6.23349e-10 0.470585 0.016479 0.393289 0.048496C0.315993 0.080513 0.24576 0.127441 0.1866 0.1866C0.127441 0.24576 0.080513 0.315993 0.048496 0.393289C0.016479 0.470585 -6.23348e-10 0.55343 0 0.637094C6.23349e-10 0.720759 0.016479 0.803604 0.048496 0.8809C0.080513 0.958196 0.127441 1.02843 0.1866 1.08759L6.10029 7L0.1866 12.9124C0.0671221 13.0319 0 13.1939 0 13.3629C0 13.5319 0.0671221 13.6939 0.1866 13.8134C0.306079 13.9329 0.468126 14 0.637094 14C0.806062 14 0.96811 13.9329 1.08759 13.8134L7 7.89971L12.9124 13.8134C13.0319 13.9329 13.1939 14 13.3629 14C13.5319 14 13.6939 13.9329 13.8134 13.8134C13.9329 13.6939 14 13.5319 14 13.3629C14 13.1939 13.9329 13.0319 13.8134 12.9124L7.89971 7L13.8134 1.08759Z" fill="black" />
                                </svg>
                                {_t("Reject")}
                            </button>
                            <button className="btn btnorange" type="submit">
                                <svg className="me-1" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 1.26174L5.02857 12L0 7.0783L1.28914 5.81656L5.02857 9.46756L14.7109 0L16 1.26174Z" fill="#253837" />
                                </svg>
                                {_t("Accept")}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AcceptGuarantor