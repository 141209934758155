import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-loader-spinner';
import useTranslator from '../hooks/useTranslator';



const AccountLoading = () => {
    const { _t } = useTranslator();

    const accountPhrases = [_t("Checking Transactions"), _t("Checking Balance"), _t("Checking status")];
    const [loadingWord, setLoadingWord] = useState(_t("Loading"));

    let i = 0;
    const loadRecursion = () => {
        if (i >= 2) {
            i = 2;
        }
        setTimeout(() => {
            console.log(i)
            setLoadingWord(accountPhrases[i]);
            ++i;
        }, 2000)
    }




    useEffect(() => {
        loadRecursion();

    }, [loadingWord])

    return (
        <>
           
                    <div className='spinner-loader'>
                        <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor='#253837'
                            barColor='#51E5FF'
                        />
                        <p style={{color:'#253837'}}>{loadingWord}</p>
                    </div>
     
        </>
    )
}

export default AccountLoading;