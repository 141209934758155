import React, { useContext, useEffect,useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AuthContext from '../../../context/auth/AuthContext';
import TenantContext from '../../../context/tenant/TenantContext';
import LandLordContext from '../../../context/landlord/LandLordContext';

import Loader from '../../../components/Loader';
import AccountLoading from '../../../components/AccountLoading';
import CommentLoading from '../../../components/CommentLoading';
import useTranslator from '../../../hooks/useTranslator';


const SharingPropertyLink = () =>{

    const { _t } = useTranslator();


    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { isLoggedIn, logout, user, userType, loadMyNotifications,loadUser  } = authContext;

    const tenantContext = useContext(TenantContext);
    const { loadingTenant, demoFun,shareProfile } = tenantContext;

    const landLordContext = useContext(LandLordContext);
    const { loadingLandlord, demoFunLandlord, setProperties, loadAllLandlords } = landLordContext;

    //extracting url parameters
    const queryParameters = new URLSearchParams(window.location.search)
    const propertyid = queryParameters.get("propid");
    const landlordid = queryParameters.get("landlordid");

    const [div,setDiv] = useState('loading');

    useEffect(()=>{
        console.log(`PropertyId is ${propertyid} --- LandlordId is ${landlordid}`);
        console.log(window.location.href)
        console.log(window.location.pathname)
    },[])

    //user checking
    useEffect(() => {
        let token = localStorage.getItem('token');
        let usertype = localStorage.getItem('usertype');
        if (token && usertype) {
            loadUser(usertype);
            loggedInUser();
        } else {
            // navigate('/login')
            notLoggedInUser();
        }

    }, [])

    const loggedInUser = () =>{
        console.log("user is logged in");
        //setDiv('loggedin');
        let sharedurl = localStorage.getItem('sharedurl');
        if(sharedurl){
            console.log("url is present");
            shareProfileFun();

        }else{
            console.log("url is not present but will be set");
            localStorage.setItem('sharedurl',window.location.href);
            shareProfileFun();
        }

    }

    //currently sharing basic profile with landlord if we need to share full profile then we can use property id to fill the property info
    const shareProfileFun = () =>{
        let userid = localStorage.getItem('saveduserid');
        shareProfile(userid, landlordid, "null", "basic", {});
        localStorage.removeItem('sharedurl')
        navigate('/home')
    }

    const notLoggedInUser = () =>{
        console.log("user is not logged in");
        if(propertyid && landlordid){
            console.log("property id set",propertyid)
            localStorage.setItem('sharedurl',window.location.href);
        };
        //setDiv('not-loggedin');
        navigate('/login')

    }


    return(
        <>
        <div className='margin-div'></div>
       
        {
            div == "loggedin" && 
            <>
                <p>{_t("Logged in user")}</p>
            </>
        }

        {
            div == "not-loggedin" && 
            <>
                <h3>{_t("You are Not Logged In Please Login or Signup")}</h3>
                <Link to='/login'>{_t("Take me to Login")}</Link>
            </>
        }

        {
            div == "loading" && 
            <>
                <CommentLoading/>
                <p>{_t("Sharing Basic Profile Please Wait")}</p>
            </>
        }



        </>
    )
};

export default SharingPropertyLink;