import { useContext, useEffect, useState } from "react";
import useTranslator from "../../hooks/useTranslator";
import LandLordContext from "../../context/landlord/LandLordContext";
import { useParams } from "react-router-dom";
import { haveValue } from "../../helper/common";
import AuthContext from "../../context/auth/AuthContext";
import ReviewRentalPaid from "./ReviewRentalPaid";
import RentalDepositPay from "./RentalDepositPay";
import Loader from "../Spinner";

const RentalReview = () => {
  const { _t } = useTranslator();
  const [formData, setFormData] = useState([]);
  const [step, setStep] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);
  const landLordContext = useContext(LandLordContext);
  const authContext = useContext(AuthContext);
  const { setLoading, updateUserInfo } = authContext;
  const { assignedPropertyId } = useParams();
  const { fetchAssignedDetails } = landLordContext;
  useEffect(() => {
    setLoading(true);
    fetchAssignedDetails(assignedPropertyId)
      .then((res) => {
        console.log(res.result, "CHECK THE RESULT HERE");
        setFormData(res.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    updateUserInfo({ assigned_property_id: assignedPropertyId });
  }, []);

  return (
    <>
      {localLoading ? (
        <Loader />
      ) : (
        <div className="foursteps-sec">
          <ReviewRentalPaid formData={formData} step={step} />

          <RentalDepositPay
            formData={formData}
            step={step}
            setStep={setStep}
            setLocalLoading={setLocalLoading}
          />
        </div>
      )}
    </>
  );
};

export default RentalReview;
