import { useContext } from "react";
import constants from "../../Utils/constants";
import useTranslator from "../../hooks/useTranslator";
import LandLordContext from "../../context/landlord/LandLordContext";

const RentalDepositOptions = (props) => {
  const { step, handleNextStep, formData, setStep, setFormData } = props;
  const { _t } = useTranslator();

  const landLordContext = useContext(LandLordContext);
  const { initiateAssignProperty } = landLordContext;

  const handlePayDeposit = (type) => {
    const temp = { ...formData };
    temp.deposit_type = type;
    setFormData(temp);
    temp.edit_id = temp?._id;
    initiateAssignProperty(temp)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setStep((prev) => prev + 1);
  };

  console.log(formData, "formdata val here");

  return (
    <>
      {step == 0 ? (
        <>
          <div className="rentaldetails-body main-rentaldetails">
            <div className="step-point rental-step-point">
              <p>2</p>
              <h3>{"Deposit Options"}</h3>
            </div>
            <div className="skorfull-deposit">
              <div className="skor-depositsec">
                <div className="skorflex-rent">
                  <h6>{_t("Skor Deposit")}</h6>
                  <p>
                    {" "}
                    {_t(
                      "With your skor you are saving upfront deposit costs"
                    )}{" "}
                  </p>
                  <div className="full-depositsec">
                    <del className="fulldeposit-text">
                      {_t("Full Deposit Amount")}:
                    </del>
                    <del className="fulldeposit-amount">
                      €{formData?.deposit_amount}
                    </del>
                  </div>
                  <div className="full-depositsec">
                    <p className="fulldeposit-text">{_t("Skor Deposit")}:</p>
                    <p className="fulldeposit-amount">
                      €{formData?.rent_amount}
                    </p>
                  </div>
                  <div className="full-depositsec border-div">
                    <p className="fulldeposit-text">
                      {_t("Monthly Subscription")}:
                    </p>
                    <p className="fulldeposit-amount">
                      €{formData?.monthly_sub_amount}
                    </p>
                  </div>
                  {/* <div className="divider"></div> */}
                  <div className="full-depositsec">
                    <p className="total-text">{_t("TOTAL")}:</p>
                    <p className="total-amount">
                      €{formData?.rent_amount + formData?.monthly_sub_amount}
                    </p>
                  </div>
                  <button
                    className="btn btngreen w-100"
                    disabled
                    // onClick={(e) => handlePayDeposit(constants.SKOR_DEPOSIT)}
                  >
                    {_t("Pay With Skor Deposit")}
                  </button>
                </div>
              </div>
              <div className="fulldeposit-sec">
                <div className="fulldeposit-header">
                  <h6>{_t("Full Deposit")}</h6>
                  <p> {_t("Pay full upfront deposit costs")} </p>
                </div>
                <div className="fulldeposit-middle">
                  <p className="deposit-text">{_t("Full Deposit Amount")}:</p>
                  <p className="deposit-amount">€{formData?.deposit_amount}</p>
                </div>
                <button
                  className="btn btngreen w-100"
                  onClick={(e) => handlePayDeposit(constants.FULL_DEPOSIT)}
                >
                  {_t("Pay With Full Deposit")}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <div className="d-flex">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="16" fill="#A3EB64" />
                                <path d="M11.8655 19.6135L8.28047 16.0939C7.97398 15.793 7.56022 15.6255 7.13068 15.6255C6.70113 15.6255 6.28737 15.793 5.98088 16.0939C5.67408 16.3951 5.5 16.8056 5.5 17.2356C5.5 17.4483 5.54269 17.6587 5.62539 17.8547L11.8655 19.6135ZM11.8655 19.6135L22.7195 8.96833C22.7196 8.96827 22.7197 8.96821 22.7197 8.96815C23.0262 8.66737 23.4399 8.5 23.8693 8.5C24.2989 8.5 24.7126 8.66743 25.0191 8.96833M11.8655 19.6135L25.0191 8.96833M25.0191 8.96833C25.3259 9.26954 25.5 9.68004 25.5 10.11C25.5 10.5401 25.3259 10.9506 25.0191 11.2518L13.0208 23.0311C12.384 23.6563 11.358 23.6563 10.7212 23.0311L5.98088 18.3773L25.0191 8.96833Z" fill="#223635" stroke="#223635" />
                            </svg>
                            <h3 className="rental_detail_heading">{_t("Deposit Options")}</h3>
                            <p>{_t("You Chose to Pay")} €{formData?.deposit_type == constants.SKOR_DEPOSIT ? +formData?.rent_amount : formData?.deposit_amount} {formData?.deposit_type == constants.SKOR_DEPOSIT ? _t(`${"Skor Deposit"}`) : _t(`${"Full Deposit"}`)}</p>
                            <a href={undefined} className="setNoInfoColor" onClick={e => setStep(0)}>{_t("Edit")}</a> */}

          <div className="depositoptions-sec">
            <div className="deposit-left">
              <div className="check-deposit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <circle cx="16" cy="16" r="16" fill="#A3EB64" />
                  <path
                    d="M11.8654 20.314L7.93019 16.4506C7.71814 16.2425 7.43055 16.1255 7.13068 16.1255C6.8308 16.1255 6.54321 16.2425 6.33117 16.4506C6.11912 16.6588 6 16.9412 6 17.2356C6 17.3813 6.02925 17.5257 6.08607 17.6604C6.14289 17.795 6.22617 17.9174 6.33117 18.0205L11.0715 22.6743C11.5138 23.1086 12.2283 23.1086 12.6705 22.6743L24.6688 10.895C24.8809 10.6868 25 10.4044 25 10.11C25 9.81564 24.8809 9.5333 24.6688 9.32512C24.4568 9.11695 24.1692 9 23.8693 9C23.5695 9 23.2819 9.11695 23.0698 9.32512L11.8654 20.314Z"
                    fill="#223635"
                  />
                </svg>
                <h3 className="rental_detail_heading">
                  {_t("Deposit Options")}
                </h3>
              </div>
              <div className="keyflex-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <circle cx="24.5" cy="24.5" r="24" stroke="#A3EB64" />
                  <path
                    d="M17.7336 24.4223C17.6403 24.7022 17.5878 24.9995 17.5878 25.3086C17.5878 26.8509 18.8417 28.1074 20.3871 28.1074C21.9326 28.1074 23.1865 26.8538 23.1865 25.3086C23.1865 23.7634 21.9326 22.5097 20.3871 22.5097C19.8506 22.5097 19.352 22.6643 18.9233 22.9267L16.4535 20.7546L17.4012 19.6934L15.9782 18.4223L15.0189 19.4952L14.3511 18.9062L15.3629 17.775L13.9399 16.5039L12.9164 17.6468L12.8056 17.7721L12.2254 18.4223L12.2312 18.4281L11.6597 19.0812L17.7365 24.4223H17.7336ZM20.3871 24.4165C20.877 24.4165 21.2794 24.8159 21.2794 25.3057C21.2794 25.7955 20.8799 26.1949 20.3871 26.1949C19.8943 26.1949 19.4978 25.7955 19.4978 25.3057C19.4978 24.8159 19.8973 24.4165 20.3871 24.4165Z"
                    fill="#253837"
                  />
                  <path
                    d="M19.6352 21.5265C19.6498 21.5236 20.0114 21.4273 20.5275 21.4273C21.6239 21.4273 23.1139 21.8297 23.9946 23.7422L24.0558 23.8734L25.7879 23.0746L25.7266 22.9434C24.6886 20.6897 22.9652 19.5469 20.6004 19.5469C20.1484 19.5469 19.6644 19.5906 19.1599 19.6752L19.0024 19.7014L19.4923 21.5585L19.6323 21.5206L19.6352 21.5265Z"
                    fill="#253837"
                  />
                  <path
                    d="M26.5113 13.6221C27.1615 14.7358 29.2989 18.6717 29.4768 22.6455L29.4826 22.7942L31.3868 22.6426L31.378 22.4997C31.1506 18.9166 29.3339 14.9399 28.2725 12.8874C28.2404 12.8233 28.22 12.7854 28.2113 12.7679L28.15 12.6367L26.418 13.4385L26.4792 13.5697C26.4792 13.5697 26.4909 13.5901 26.5084 13.6221H26.5113Z"
                    fill="#253837"
                  />
                  <path
                    d="M21.6435 13.9603C22.3958 14.8174 26.172 19.281 26.6531 22.8932L26.6735 23.0361L28.5631 22.7854L28.5426 22.6425C28.0382 18.8437 24.6265 14.5171 23.1831 12.832C23.1423 12.7854 23.116 12.7533 23.1044 12.7387L23.014 12.625L21.5239 13.8145L21.6143 13.9282C21.6143 13.9282 21.626 13.9399 21.6406 13.9603H21.6435Z"
                    fill="#253837"
                  />
                  <path
                    d="M32.7703 14.4622L32.7179 14.3281L30.9449 15.0337L30.9974 15.1678C33.6976 21.9491 33.9746 28.0162 31.741 31.3952C30.0352 33.9754 27.1834 34.0658 27.0609 34.0687H27.0346C26.746 34.0832 26.4602 34.0891 26.1832 34.0891C23.1418 34.0891 20.777 33.2611 19.147 31.6284C16.4147 28.8908 16.3797 24.78 16.3797 24.7421L16.3856 24.5993L15.435 24.5497L14.4844 24.4972L14.4756 24.643C14.4756 24.6897 14.4319 29.5876 17.7852 32.9637C19.7856 34.9754 22.6111 35.9987 26.1861 35.9987C26.4894 35.9987 26.8014 35.99 27.1134 35.9754C27.5158 35.9637 31.1111 35.7771 33.3156 32.4739C35.9516 28.5206 35.7534 21.955 32.7703 14.4622Z"
                    fill="#253837"
                  />
                </svg>
                <p>
                  {_t("You Chose to Pay")} €
                  {formData?.deposit_type == constants.SKOR_DEPOSIT
                    ? +formData?.rent_amount
                    : formData?.deposit_amount}{" "}
                  {formData?.deposit_type == constants.SKOR_DEPOSIT
                    ? _t(`${"Skor Deposit"}`)
                    : _t(`${"Full Deposit"}`)}
                </p>
              </div>
            </div>
            <div className="deposit-right">
              <a
                href={undefined}
                className="setNoInfoColor"
                onClick={(e) => setStep(0)}
              >
                {_t("Edit")}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RentalDepositOptions;
