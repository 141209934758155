import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthContext from '../context/auth/AuthContext';
import { haveValue } from '../helper/common';
import { useCookies } from 'react-cookie';


const ProtectedRoute = ({ auth, component, ...rest }) => {
    const authContext = useContext(AuthContext);
    const { user, loadUser } = authContext
    const navigate = useNavigate()
    const params = useParams();
    const { assignedPropertyId = null } = params

    const queryParam = new URLSearchParams(window.location.search);
    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])
    const [hasError, setHasError] = useState(false)

    if (haveValue(queryParam.get('sharedProfileLink')) && !haveValue(cookies.sharedProfileLink)) {
        setCookie('sharedProfileLink', queryParam.get('sharedProfileLink'), { maxAge: 1800 })
    }
    if (haveValue(queryParam.get('requestProfileLink')) && !haveValue(cookies.requestProfileLink)) {
        setCookie('requestProfileLink', queryParam.get('requestProfileLink'), { maxAge: 604800 })
    }
    if (haveValue(queryParam.get('gurantorFor')) && !haveValue(cookies.gurantorFor)) {
        setCookie('gurantorFor', queryParam.get('gurantorFor'), { maxAge: 604800 })
    }
    if (haveValue(queryParam.get('navigateTo'))) {
        setCookie('navigateTo', queryParam.get('navigateTo'), { maxAge: 1800 })
    }

    if (haveValue(assignedPropertyId) && window.location.pathname.includes('accept/assigned/property') && haveValue(queryParam.get('email'))) {
        setCookie('assignedPropertyId', assignedPropertyId, { maxAge: 1800 })
    }

    if (haveValue(cookies.navigateTo)) {

        if (haveValue(user?._id)) {
            const navigateTo = cookies.navigateTo;
            console.log(navigateTo, "INSIDE PROTECTED ROUTE FUNCTION")
            removeCookie('navigateTo', { maxAge: 1800 })
            navigate(`/${navigateTo}`)
        }
    }
    if (haveValue(cookies.assignedPropertyId)) {

        const id = cookies.assignedPropertyId
        removeCookie('assignedPropertyId', { maxAge: 1800 })
        navigate(`/accept/assigned/property/${id}`)
    }

    useEffect(() => {

        if (user && haveValue(user?.has_profile) && user?.has_profile != true) {

            if(user?.is_university){
                navigate("/university/stepform")
            }else{
                navigate("/stepform")
            }
        }

    }, [user])


    let token = localStorage.getItem('token')
    let is_verified = localStorage.getItem('is_verified')
    let type = localStorage.getItem('usertype')

    if (!(token && is_verified && is_verified == "true")) {
        console.log("not logged in ")
        return <Navigate to="/userrole" replace />;
    }

    return component;

};

export default ProtectedRoute;