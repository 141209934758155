import React from "react";
import { Comment } from  'react-loader-spinner'


const CommentLoading = () => {
    return (
        <div className='global-loader'>
        <div className="child-loader">
        <Comment
            visible={true}
            height="80"
            width="80"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="#fff"
            backgroundColor="#F4442E"
        />

        </div>
        </div>
    )
};

export default CommentLoading;