import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TenantContext from '../../../context/tenant/TenantContext';
import AuthContext from '../../../context/auth/AuthContext';
import LandLordContext from '../../../context/landlord/LandLordContext';
import axios from 'axios'

import backicon from '../../../assets/img/back.png'
import calendar from '../../../assets/img/calendar.png'
import useTranslator from '../../../hooks/useTranslator';



const PropertyLink = () => {

    const { _t } = useTranslator();


    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { isLoggedIn, logout, user, userType, loadMyNotifications, notifications } = authContext;

    const tenantContext = useContext(TenantContext);
    const { loadingTenant, demoFun, loadUser, shareProfile } = tenantContext;

    const landLordContext = useContext(LandLordContext);
    const { loadingLandlord, demoFunLandlord, setProperties, requestFullProfile, properties, loadProperties } = landLordContext;

    const queryParameters = new URLSearchParams(window.location.search)
    const tenantid = queryParameters.get("tenantid"); //when sending notificatin with property info to tenant
    const notificationid = queryParameters.get("notificationid"); //when a tenant is opening the link and changing the values
    const sharedprofileid = queryParameters.get('sharedprofileid');
    const todo = queryParameters.get('todo')
    // console.log(tenantid);

    let text;
    let result;

    useEffect(() => {
        console.log(todo);
        // if(todo == "sendtotenant"){
        //     console.warn("matched")
        // }
        if (sharedprofileid && userType == "tenant") {
            setPageValues({ ...pageValues, title: _t("Property Information"), button: _t("Next") });
            loadPropertyInfo();
        }
        if (userType == "landlord") {
            setPageValues({ ...pageValues, title: _t("Send Link"), button: _t("Send") });
        }

        loadProperties(user?._id);


    }, [])



    const loadPropertyInfo = () => {
        //get request to single shared profile
        axios.get(`${process.env.REACT_APP_SERVER_URL}/shared-profile/singleshared/${sharedprofileid}`, { headers: { "Accept-Language": localStorage.getItem('i18nextLng') } })
            .then(res => {
                console.log(res);
                setLink(res.data.singleSharedProfile?.property);
                setLandlordid(res.data.singleSharedProfile.landlordid);

            })
            .catch(err => {
                console.log(err);
                let err_res = err;
                //toast.error(err_res.message);
            })

    }

    const [pageValues, setPageValues] = useState({
        title: _t("Send Link Of The Property"),
        button: _t("Send")
    });

    const [landlordid, setLandlordid] = useState("");
    const [propertyid, setPropertyid] = useState("");


    const [link, setLink] = useState({
        propertyid: "",
        have_you_identified_property: "",
        name_on_lease: "",
        address: "",
        muncipality: "",
        flat_number: "",
        postcode: "",
        will_you_be_sharing_apartment: "",
        rent_per_month: "",
        deposit_asked: "",
        intended_date_of_lease: "",
        tenancy_period: "",
        are_you_using_agent: "",
        name_of_agent: "",
        email_of_agent: "",
        name_of_company: "",
        email_of_sharer: "",
    })

    useEffect(() => {
        console.log("LINK VALUE IS HERE", link)
    }, [link])

    const onChange = (e) => {
        setLink({ ...link, [e.target.name]: e.target.value });
    }

    const onChangeProperty = (e) => {
        setPropertyid(e.target.value);
        findPropertyandFill(e.target.value);
        console.log(e.target.value);
    }

    const findPropertyandFill = (propertyid) => {
        for (let property of properties) {
            if (property._id == propertyid) {
                console.log("property matches");
                setLink(property);

            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(link);
        if (userType == "landlord") {
            sendLink();
        }
        if (userType == "tenant") {
            shareProfileWithformDetails();
        }
    }

    const sendLink = () => {
        console.log("link is being sent to tenant");
        requestFullProfile(user?._id, tenantid, sharedprofileid, link);

        if (userType == "landlord") {
            navigate('/profile-request');
        }
        if (userType == "tenant") {
            navigate('/profile-shared')
        }
    }

    const cancelOperation = () => {
        navigate('/home');
    }

    const shareProfileWithformDetails = (e) => {
        console.log("form is submitted and being sent to landlord", link);
        shareProfile(user?._id, landlordid, sharedprofileid, "full", link);
        navigate('/profile-shared');
    }

    const goBack = () => {
        if (userType === "landlord") {
            navigate('/profile-request')
        } else {
            navigate('/profile-shared')
        }
    }


    return (
        <>
            {/* <div className='margin-div'></div> */}




            <div className='tabs-box-pattern'>
                <div className="tabs-item d-block">
                    <div className='row'>
                        <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
                            <div><Link to='/notification'><img src={backicon} alt="backbtn" /></Link>
                            </div>
                            <h3 className="mb-0">{pageValues.title}</h3>
                            <div></div>
                        </div>
                        <br />
                        <br />

                        <form onSubmit={onSubmit}>
                            <div className="row">

                                <div className="col-12">
                                    {/* <div className="head-set">
                                    <h3>Property Information</h3>
                                </div> */}
                                </div>
                                <div className="col-sm-6 col-12">
                                    {
                                        userType == "landlord" ?
                                            <>
                                                <div className="info-wapper disable-type">
                                                    <label>{_t("Property Address")}</label>
                                                    {/* <input type="text" className="custom-input" name="type_of_employee" placeholder="type of employee" onChange={onChangeTenant} /> */}
                                                    <select className="form-select" aria-label="Default select example" name="address"
                                                        onChange={onChangeProperty}>
                                                        <option value="empty">{_t("Select Your Property")}</option>
                                                        {
                                                            properties && properties.map((property, index) => (
                                                                <option value={property?._id}>{property?.address}</option>
                                                            ))
                                                        }


                                                    </select>
                                                </div>

                                            </>
                                            : <> </>
                                    }

                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type">

                                        <label>{_t("Post Code")}</label>
                                        <input type="text" className="custom-input" name="postcode" placeholder={_t("post code")} onChange={onChange} value={link?.postcode || ""} disabled="disable" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type">

                                        <label>{_t("Flat Number")}</label>
                                        <input type="text" className="custom-input" name="flat_number" placeholder={_t("Enter flat number")} onChange={onChange} value={link?.flat_number || ""} disabled="disable" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type">

                                        <label>{_t("Municipality")}</label>
                                        <input type="text" className="custom-input" name="muncipality" placeholder={_t("Muncipality")} onChange={onChange} value={link?.muncipality || ""} disabled="disable" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type">

                                        <label>{_t("Province")}</label>
                                        <input type="text" className="custom-input" name="province" placeholder={_t("Province")} onChange={onChange} value={link?.province || ""} disabled="disable" />
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type" >

                                        <label>{_t("Rent Per Month")}</label>
                                        <input type="number" className="custom-input" name="rent_per_month" placeholder={_t("rent per month")} onChange={onChange} value={link?.rent_per_month || ""} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper disable-type">

                                        <label>{_t("Deposit Asked")}</label>
                                        <input type="number" className="custom-input" name="deposit_asked" placeholder={_t("deposit asked")} onChange={onChange} value={link?.deposit_asked || ""} />

                                    </div>
                                </div>

                            </div>

                            {
                                userType === "tenant" &&
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="head-set">
                                                <h3>{_t("Tenant Information")}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="info-wapper">
                                                <label>{_t("Name On Lease")}</label>
                                                <input type="text" className="custom-input" name="name_on_lease" placeholder={_t("Enter Name")} onChange={onChange} value={link?.name_on_lease || ""} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="info-wapper">
                                                <label>{_t("Intended Date Of Lease")}</label>
                                                <div className="Intended-lease">
                                                    <div className='btn-relate'>
                                                        <input type="date" id='intended_date_of_lease' name="intended_date_of_lease" onChange={onChange} className="custom-input calender-input w-75" placeholder="MM/DD/YY" value={link?.intended_date_of_lease} />
                                                        {/* <input type="date" className='input-height' name="intended_date_of_lease" onChange={onChange} /> */}
                                                        {/* <input type="date" name="intended_date_of_lease" className="calender-input" onChange={onChange}></input> */}
                                                        {/* <img className='btn-calender' src={calendar} alt="" /> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm-6 col-12">
                                            <div className="info-wapper">
                                                <label>{_t("Tenancy Period")}</label>
                                                <select name="tenancy_period" id="tenancy_period" aria-placeholder={_t("Self Employed")} onChange={onChange}>
                                                    <option value="">{_t("Select Period")}</option>
                                                    <option value="6">6 {_t("Months")}</option>
                                                    <option value="9">9 {_t("Months")}</option>
                                                    <option value="12">12 {_t("Months")}</option>
                                                    <option value="18">18 {_t("Months")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="info-wapper">
                                                <label>{_t("Will You Be Sharing Apartment?")}</label>
                                                <div className="select-radio">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="will_you_be_sharing_apartment" value="yes" onChange={onChange} checked={link?.will_you_be_sharing_apartment == "yes" ? true : false} />
                                                        <label className="form-check-label" for="female">{_t("Yes")}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="will_you_be_sharing_apartment" value="no" onChange={onChange} checked={link?.will_you_be_sharing_apartment == "no" ? true : false} />
                                                        <label className="form-check-label" for="">{_t("No")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <div className="info-wapper">
                                                <label>{_t("Email of Sharer")}</label>
                                                <input type="text" name="email_of_sharer" className="custom-input" placeholder={_t("Enter Email")} onChange={onChange} />
                                            </div>
                                        </div>



                                    </div>
                                </>
                            }

                            {/* <div className="d-flex justify-content-end">
                            </div> */}

                            <div className="row">

                                <div>


                                    <button onClick={cancelOperation} className="btn-fill-border ms-md-3">{_t("Cancel")}</button>
                                    <button type="submit" className="btn-fill2">{pageValues.button}</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </>
    )
}

export default PropertyLink