import {
    BASICSHARED_LOADED,
DEMO_LANDLORD, ERROR_RESOLVE, FULLSHARED_LOADED, LANDLORD_LOADED, LOAD_PROPERTY, PROPERTY_ADDED, PROPERTY_DELETED, 
PROPERTY_EDITED, PROPERTY_ERROR, PROPERTY_LOADED, SET_PROPERTY, SHARED_PROFILE_FAILED_LOADING, TOGGLE_LOADING,

} from '../types';

export default (state,action) =>{
    //console.log(state,action);
    switch(action.type){
        
        case LANDLORD_LOADED:
            return{
                ...state,
                landlords:action.payload,
        }


        case TOGGLE_LOADING:
            return{
                ...state,
                landlord_loading:action.payload
        }
        case PROPERTY_LOADED:
            return{
                ...state,
                properties:[...state.properties,action.payload],
                
        }
        case LOAD_PROPERTY:
            return{
                ...state,
                landlord_loading:false,
                properties:action.payload,
                
        }
        case SET_PROPERTY:
            return{
                ...state,
                current_property:action.payload
        }
        case PROPERTY_ADDED:
            return{
                ...state,
                landlord_loading:false,
                properties:action.payload
                
        }

        case PROPERTY_EDITED:
            return{
                ...state,
                landlord_loading:false,
        }

        case PROPERTY_DELETED:
            return{
                ...state,
                landlord_loading:false,
                properties:state.properties.filter(property=>property._id !== action.payload)
        }

        case PROPERTY_ERROR:
            return{
                ...state,
                landlord_loading:false,
                landlord_error:action.payload,
        }

        case ERROR_RESOLVE:
            return{
                ...state,
                landlord_loading:false,
                landlord_error:null,
        }

        case FULLSHARED_LOADED:
            return{
                ...state,
                fullProfileReceived:action.payload

            }

        case BASICSHARED_LOADED:
            return{
                ...state,
                basicProfileReceived:action.payload
    
            }
        case SHARED_PROFILE_FAILED_LOADING:
            return{
                ...state,
            }




        default:
            return state
    }
}