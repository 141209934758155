import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PdfToImage = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const renderPdfToCanvas = async () => {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);  // Render only the first page for this example
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      // Convert canvas to image
      const image = canvas.toDataURL('image/png');
      setImageSrc(image);
    };

    renderPdfToCanvas();
  }, [pdfUrl]);

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {imageSrc && <img src={imageSrc} alt="PDF as image" />}
    </div>
  );
};

export default PdfToImage;
