// src/components/SaveCard.js
import React, { useContext, useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import TenantContext from "../context/tenant/TenantContext";
import AuthContext from "../context/auth/AuthContext";

const SaveCard = ({ closeModal }) => {
    const stripe = useStripe();
    const elements = useElements();

    const tenantContext = useContext(TenantContext);
    const { saveCard, getAllSavedCard } = tenantContext;

    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
            console.error(error);
        } else {
            // Send the token to your server for further processing
            console.log('tokentokentokentoken', token);
            mapCardWithUser(token?.id)
        }
    };


    const mapCardWithUser = async (token) => {
        console.log('token', token)
        const result = await saveCard(user?._id, token)
        console.log('result', result)
        if (result?.data?.status === 'success') {
            closeModal()
            // window.location.reload()
        }
    }


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
            <h1>Secure Payment</h1>
            <p>Enter your card details to complete the payment:</p>
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                {error && <div style={{ color: '#9e2146', marginTop: '10px' }}>{error}</div>}
                <button type="submit" style={{ marginTop: '10px' }} disabled={!stripe || loading}>
                    {loading ? 'Processing...' : 'Pay'}
                </button>
            </form>

            {/* <div onClick={() => fetchSavedCards(user?._id)} >Getcards</div> */}
        </div>
    );

};

export default SaveCard;
