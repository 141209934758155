import { Button, Form, Modal } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";

const CardSelect = (props) => {
  const {
    cardModal,
    setCardModal,
    setIsDomesticCard,
    isDomesticCard,
    proceedPayment,
  } = props;

  const { _t } = useTranslator();

  const handleClose = () => {
    setCardModal(false);
  };

  return (
    <>
      <Modal
        className="card_select_modal modal-lg"
        show={cardModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h5 className="modal-main-heading">{_t("Card Fees")}</h5>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="formcontrolbar row">
            <label class="customradiobar card-fee col-sm-6">
              <input
                type="radio"
                name="go_back_reason"
                checked={isDomesticCard == true}
                onClick={(e) => setIsDomesticCard(true)}
              />
              <div class="boxes">
                <div className="d-flex gap-2">
                  <img src="/images/BankEU.svg" alt="" />
                  <p>{_t("Credit card (EU)")}</p>
                </div>
                <p className="orange_text">{_t("Free")}</p>
              </div>
            </label>

            <label class="customradiobar card-fee col-sm-6">
              <input
                type="radio"
                name="go_back_reason"
                checked={isDomesticCard == false}
                onClick={(e) => setIsDomesticCard(false)}
              />
              <div class="boxes">
                <div className="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M13 0C5.8318 0 0 5.8318 0 13C0 20.1682 5.8318 26 13 26C20.1682 26 26 20.1682 26 13C26 5.8318 20.1682 0 13 0ZM2.6 13C2.6 11.8313 2.8028 10.7094 3.1603 9.6603L5.2 11.7L7.8 14.3V16.9L10.4 19.5L11.7 20.8V23.3103C6.5793 22.6668 2.6 18.2936 2.6 13ZM21.229 19.3349C20.3801 18.6511 19.0931 18.2 18.2 18.2V16.9C18.2 16.2104 17.9261 15.5491 17.4385 15.0615C16.9509 14.5739 16.2896 14.3 15.6 14.3H10.4V10.4C11.0896 10.4 11.7509 10.1261 12.2385 9.63848C12.7261 9.15088 13 8.48956 13 7.8V6.5H14.3C14.9896 6.5 15.6509 6.22607 16.1385 5.73848C16.6261 5.25088 16.9 4.58956 16.9 3.9V3.3657C20.7064 4.9114 23.4 8.645 23.4 13C23.3995 15.2941 22.6357 17.5227 21.229 19.3349Z"
                      fill="#7F7F7F"
                    />
                  </svg>
                  <p>{_t("Credit card (International)")}</p>
                </div>
                <p className="orange_text">{_t("3.5% service charges")}</p>
              </div>
            </label>

            <Button
              className="btngreen marginTop20"
              disabled={!haveValue(isDomesticCard)}
              onClick={proceedPayment}
            >
              {_t("Proceed with payment")}
            </Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardSelect;
