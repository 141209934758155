import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";
import LandLordContext from "../context/landlord/LandLordContext";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { FaArrowLeft } from "react-icons/fa6";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import '../assets/css/auth.css'
import Loader from "../components/Loader";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import useTranslator from "../hooks/useTranslator";
import { useCookies } from "react-cookie";
import TenantContext from "../context/tenant/TenantContext";
import { haveValue } from "../helper/common";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import i18next from "i18next";

const Login = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const { hash } = useLocation();
  const snackbar = useSnackbar();

  const user_type = localStorage.getItem("usertype");
  const authContext = useContext(AuthContext);
  const {
    toggleLogin,
    loading,
    setLoading,
    loadUser,
    setUserType,
    verified,
    forgotPassword,
  } = authContext;

  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord, demoFunLandlord, setProperties, properties } =
    landLordContext;

  const tenantContext = useContext(TenantContext);
  const { shareProfile, linkGurantor } = tenantContext;

  const queryParameters = new URLSearchParams(window.location.search);
  const logincode = queryParameters.get("code");

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const handleLanguageChange = async (e) => {
    console.log(e.target.value);
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    console.log("Lng should be changed by now");
    setCurrentLang(langVal);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let is_verified = localStorage.getItem("is_verified");

    if (token && is_verified) {
      toggleLogin(true);
      console.log("user is logged in");
      loadUser(user_type);
      if (user_type == "tenant") {
        navigate("/tenant");
      } else {
        navigate("/home");
      }
    }
  }, [toggleLogin, navigate]);

  useEffect(() => {
    if (logincode) {
      setLoading(true);
      console.log(logincode);
      let user_type = localStorage.getItem("usertype");
      if (!user_type) {
        toast.error(_t("please select your user type"));
      } else {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/users/linkedin-login/${logincode}?usertype=${user_type}`,
            {
              headers: {
                "Accept-Language": localStorage.getItem("i18nextLng"),
              },
            }
          )
          .then(async (res) => {
            let serverResponse = res;
            console.log(res.data);
            localStorage.setItem("token", serverResponse.data.token);
            localStorage.setItem("is_verified", true);
            toggleLogin(true);
            await loadUser(user_type);
            setLoading(false);
            if (
              haveValue(cookies.sharedProfileLink) &&
              user_type == "landlord"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                shareProfile(
                  cookies.sharedProfileLink,
                  res?.data?.user?._id,
                  "null",
                  "full",
                  { shareType: "tLink" }
                )
                  .then((res) => {
                    removeCookie("sharedProfileLink", { maxAge: 1800 });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            if (
              haveValue(cookies.requestProfileLink) &&
              user_type == "tenant"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                if (res?.data?.user?.docSigned) {
                  shareProfile(
                    res?.data?.user?._id,
                    cookies.requestProfileLink,
                    "null",
                    "full",
                    { shareType: "lLink" }
                  )
                    .then((res) => {
                      removeCookie("requestProfileLink", { maxAge: 1800 });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  navigate("/profile-shared?agreement=true");
                }
              }
            }
            if (serverResponse.data.user.has_profile) {
              if (
                serverResponse.data.user.role == "tenant" &&
                !serverResponse.data.user.kyc_success
              ) {
                // navigate("/home");
                navigate("/tenant");
              } else {
                navigate("/home");
                // navigate("/tenant");
                //setting properties in landlord state
                console.log("profile exist of landlord");
              }
            } else {
              if (localStorage.getItem("usertype") == "tenant") {
                navigate("/tenant");
              } else {
                navigate("/home");
              }
              console.log("profile  NOT exist");
            }
            //navigate('/home');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            navigate("/login");
            //toast.error(err.data.message);
            toast.error(_t("Please sign up first using linkedin"));
          });
      }
    }
  }, [logincode]);

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
    type: "",
    checkedTenant: false,
    checkedLandlord: false,
    checked: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useEffect(() => {
    if (hash === "#tenant") {
      setShowForgotPassword(false);
      setUserLogin((state) => ({
        ...state,
        type: "tenant",
        checkedTenant: true,
      }));
    } else if (hash === "#landlord") {
      setShowForgotPassword(false);
      setUserLogin((state) => ({
        ...state,
        type: "landlord",
        checkedLandlord: true,
      }));
    } else {
      navigate("/userrole");
    }
  }, []);

  const { checked, checkedLandlord, checkedTenant, user } = userLogin;

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error(_t("You have entered an invalid email address"));
    return false;
  };

  const onChange = (e) => {
    if (e.target.name == "email")
      setUserLogin({
        ...userLogin,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    if (checked === false) {
      setUserLogin({ ...userLogin, checked: true });
    } else {
      setUserLogin({ ...userLogin, checked: false });
    }
  };

  const handleUsertype = (e) => {
    setUserType(e.target.value);
    if (e.target.value === "landlord") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: true,
        checkedTenant: false,
        type: "landlord",
      });
    }
    if (e.target.value === "tenant") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: false,
        checkedTenant: true,
        type: "tenant",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(userLogin);
    //login(userLogin);

    if (userLogin.email === "" || userLogin.password === "") {
      toast.error(_t("Please Input Your Credentials"));
    } else {
      if (validateEmail(userLogin.email)) {
        setLoading(true);
        //login(userLogin);
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/users/login`, userLogin, {
            headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
          })
          .then((res) => {
            let serverResponse = res;
            console.log(serverResponse.data);
            if (serverResponse.data.token) {
              localStorage.setItem("token", serverResponse.data.token);
              localStorage.setItem("is_verified", true);
              localStorage.setItem("saveduserid", serverResponse.data.user._id);
              localStorage.setItem(
                "external_customer_id",
                serverResponse.data.user._id
              );
              setLoading(false);
              toggleLogin(true);
              loadUser(userLogin.type);
              if (
                haveValue(cookies.sharedProfileLink) &&
                userLogin.type == "landlord"
              ) {
                if (haveValue(res?.data?.user?._id)) {
                  shareProfile(
                    cookies.sharedProfileLink,
                    res?.data?.user?._id,
                    "null",
                    "full",
                    { shareType: "tLink" }
                  )
                    .then((res) => {
                      removeCookie("sharedProfileLink", { maxAge: 1800 });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
              //console.log(serverResponse.data.user.has_profile);
              snackbar.showMessage(_t("Logged In Successfully"));
              // navigate('/')
              if (serverResponse.data.user.has_profile) {
                if (
                  serverResponse.data.user.role == "tenant" &&
                  !serverResponse.data.user.kyc_success
                ) {
                  // navigate("/home");
                  navigate("/tenant");
                } else {
                  if (localStorage.getItem("usertype") == "tenant") {
                    navigate("/tenant");
                  } else {
                    navigate("/home");
                  }
                  //setting properties in landlord state
                  console.log("profile exist of landlord");
                }
              } else {
                if (localStorage.getItem("usertype") == "tenant") {
                  navigate("/tenant");
                } else {
                  navigate("/home");
                }
                console.log("profile  NOT exist");
              }
            } else {
              setLoading(false);
              toast.error(_t("Login Failed"));
            }
          })
          .catch((err) => {
            setLoading(false);
            let emsg = err;
            console.log(emsg);

            if (err.response) {
              toast.error(err?.response?.data?.message);
              toast.error(err?.response?.data?.msg);
            } else {
              toast.error(err?.message);
            }

            navigate("/login");
          });
      }
    }
  };

  const loginLinkedin = () => {
    // let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=http://localhost:3000/login`;
    let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_URL_NAME}/login`;

    let user_type = localStorage.getItem("usertype");
    if (!user_type) {
      toast.error(_t("Please specify user type first"));
    } else {
      // window.open(url, "_self");
      window.open(live_url, "_self");
    }
  };

  const handleRedirectToLandingPage = () => {
    window.location.replace("https://skortorent.com/");
  };

  const handleShowPassword = (val) => {
    if (val) {
      setPasswordType("text");
      setShowPassword((oldVal) => !oldVal);
    } else {
      setPasswordType("password");
      setShowPassword((oldVal) => !oldVal);
    }
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
    setShowForgotPassword(true);
  };

  const onSubmitForgotPassword = async (e) => {
    e.preventDefault();
    if (userLogin.email === "") {
      snackbar.showMessage(_t("Please Enter Your Email"));
    } else {
      const data = {
        email: userLogin?.email,
        type: localStorage.getItem("usertype"),
      };
      const res = await forgotPassword(data);
      console.log("formsubmitted");
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="Auth-wrapper">
        <img
          className="auth-shape-center"
          src="../images/LineAuth.svg"
          alt="logo"
        />

        <div className="Auth-bar">
          <div className="authbar">
            <div className="logobar" onClick={handleRedirectToLandingPage}>
              <Container>
                <Link to="https://skortorent.com/">
                  <img src="../images/logo.svg" alt="logo" />
                </Link>
              </Container>
            </div>
            <Container>
              <div className="authbar-inner">
                <div className="leftbar">
                  <div className="hero-center">
                    {localStorage.getItem("usertype") == "tenant" ? (
                      <img src="../images/TenantUserRole.webp" alt="logo" />
                    ) : (
                      <img
                        className="signp_img"
                        src="../images/LandlordUserRole.webp"
                        alt="logo"
                      />
                    )}
                  </div>
                  <div className="emptybar"></div>
                </div>
                <div className="rightbar">
                  <img
                    className="auth-shapetopright"
                    src="../images/handAuth.svg"
                    alt="logo"
                  />
                  <div className="right-contentbar">
                    <div className="backbtnbar">
                      <Link to="/userrole" className="backbtn">
                        <FaArrowLeft />
                        {_t("Go Back")}
                      </Link>
                    </div>
                    <div className="heading_lng_div">
                      {showForgotPassword ? (
                        <h4>{_t("Forgot Password")}</h4>
                      ) : (
                        <h4>
                          {_t("login as")}{" "}
                          {user_type === "tenant" ? "Tenant" : "Landlord"}
                        </h4>
                      )}
                      <div class="menu-togglebar">
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "en-us" ? "checked" : ""}
                            name="radio"
                            value={"en-us"}
                          />
                          <span class="checkmark1">EN</span>
                        </label>
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "es" ? "checked" : ""}
                            name="radio"
                            value={"es"}
                          />
                          <span class="checkmark1">ES</span>
                        </label>
                      </div>
                    </div>
                    {showForgotPassword ? (
                      <form onSubmit={onSubmitForgotPassword}>
                        <div className="or-bar d-none">
                          <p>&nbsp;</p>
                        </div>
                        <div className=" auth-form d-blockform">
                          <div className="forminput">
                            <label className="forminput">{_t("Email")}</label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email"
                              required
                              placeholder={_t("Email")}
                              onChange={onChange}
                            />
                          </div>

                          <div className="btngroup">
                            <button className="btn btnorange" type="submit">
                              {_t("Send OTP")}
                            </button>
                            <h4 className="signup-text">
                              {_t("Don't have an account")}?{" "}
                              <Link
                                to={`/register#${localStorage.getItem(
                                  "usertype"
                                )}`}
                              >
                                {_t("Sign Up")}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={onSubmit}>
                        <div className="linkedbtn">
                          <Button className="btn-fill" onClick={loginLinkedin}>
                            <BsLinkedin />
                            {_t("Login With LinkedIn")}
                          </Button>
                        </div>

                        <div className="or-bar">
                          <p>{_t("Or")}</p>
                        </div>
                        <div className=" auth-form d-blockform">
                          <div className="forminput">
                            <label className="forminput">{_t("Email")}</label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email"
                              placeholder={_t("Email")}
                              onChange={onChange}
                            />
                          </div>
                          <div className="forminput">
                            <label className="forminput">
                              {_t("Password")}
                            </label>
                            <div className="password-div">
                              <input
                                type={passwordType}
                                className="form-control user-auth icon-eye"
                                name="password"
                                id="enter_password"
                                placeholder={_t("Password")}
                                onChange={onChange}
                              />

                              {showPassword ? (
                                <VscEye
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(false)}
                                />
                              ) : (
                                <VscEyeClosed
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(true)}
                                />
                              )}
                            </div>
                          </div>

                          <div className="btngroup">
                            <button className="btn btnorange" type="submit">
                              {_t("Login")}
                            </button>
                            <h4 className="signup-text">
                              {_t("Don't have an account")}?{" "}
                              <Link
                                to={`/register#${localStorage.getItem(
                                  "usertype"
                                )}`}
                              >
                                {_t("Sign Up")}
                              </Link>
                            </h4>
                            <h4 className="signup-text">
                              <Link
                                to={undefined}
                                onClick={handleForgotPassword}
                              >
                                {_t("Forgot Password")}
                              </Link>
                            </h4>
                          </div>
                        </div>

                        {/* 
                      {/* <div className="login-bottom">
                        <h4 className="signup-text">
                          {_t("Don't have an account")}?{" "}
                          <Link to="/register">{_t("Sign Up")}</Link>
                        </h4>
                      </div> */}
                      </form>
                    )}
                  </div>
                  <div className="hero-foot">
                    <Container>
                      <div className="terms-bar">
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/cookies.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/cookies.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        )}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
