import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserScoreProfile from '../common/userProfile/UserScoreProfile';
import AuthContext from '../../context/auth/AuthContext';
import backicon from '../../assets/img/back.png';
import useTranslator from '../../hooks/useTranslator';

const TenantScore = () => {
   const { _t } = useTranslator();


   const authContext = useContext(AuthContext);
   const { user } = authContext;

   return (
      <>
         {/* <div className='margin-div'></div> */}
         <div className='tabs-box-pattern'>
            <div className="tabs-item d-block tabs-wapper2">
            <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
                  <div><Link to='/profile-shared'><img src={backicon} alt="backbtn" /></Link>
                  </div>
               </div>

               <UserScoreProfile name={"hello"} scoreProfile={user}/>

         </div>
         </div>
      </>
   )
};

export default TenantScore;