import { useNavigate, useParams } from "react-router-dom";
import constants from "../../Utils/constants";
import useTranslator from "../../hooks/useTranslator";
import { useContext, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import TenantContext from "../../context/tenant/TenantContext";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import LandLordContext from "../../context/landlord/LandLordContext";
import { haveValue } from "../../helper/common";

const RentalDepositPay = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const authContext = useContext(AuthContext);
  const { setLoading } = authContext;
  const landLordContext = useContext(LandLordContext);
  const { initiateAssignProperty } = landLordContext;
  const tenantContext = useContext(TenantContext);
  const { uploadDepositProof, initiateInespay, checkInespayStatus } =
    tenantContext;
  const { _t } = useTranslator();
  const { formData, step, setStep, setLocalLoading } = props;
  const [show, setShow] = useState(false);
  const { assignedPropertyId } = useParams();
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);

  const dataReturn = queryParameters.get("dataReturn") || null;
  const signatureDataReturn =
    queryParameters.get("signatureDataReturn") || null;

  if (haveValue(dataReturn) && haveValue(signatureDataReturn)) {
    var count = 0;
    navigate(`/review/rental/${assignedPropertyId}`);
    setLocalLoading(true);
    let interval = setInterval(() => {
      checkInespayStatus(assignedPropertyId)
        .then((res) => {
          if (res.continue) {
            if (res.success_res.status == constants.COMPLETED) {
              setLocalLoading(false);
              clearInterval(interval);
              // navigate(`/awarded/property/${assignedPropertyId}`)
              navigate(`/tenant`);
            }
          }
          count++;
        })
        .catch((err) => {
          count++;
          console.log(err, "Error in fetching payment details!");
        });
      if (count >= 5) {
        setLocalLoading(false);
        navigate(`/review/rental/${assignedPropertyId}`);
        clearInterval(interval);
        toast.error(_t("Error in fetching payment details!"));
      }
    }, 3000);
  }

  const handlePayNow = () => {
    setLoading(true);
    initiateInespay(assignedPropertyId)
      .then((res) => {
        if (res.continue)
          window.location.replace(res.success_res.singlePayinLink);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    // const sendData = { ...formData };
    // sendData.edit_id = formData?._id
    // sendData.status = constants.AWARDED;
    // initiateAssignProperty(sendData)
    //     .then(res => {
    //         console.log(res)
    //         if (haveValue(res.status) && res.status == 'success') {
    //             navigate(`/awarded/property/${assignedPropertyId}`);
    //         } else {
    //             toast.error(_t('Error while paying deposit!'))
    //         }
    //         setLoading(false)
    //     })
    //     .catch(err => {
    //         setLoading(false)
    //         toast.error(_t('Error while paying deposit'))
    //         console.log(err)
    //     })
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    formData.append("assignId", assignedPropertyId);

    setFileData(formData);
  };

  const handleFileUpload = async () => {
    try {
      setLoading(true);
      uploadDepositProof(fileData)
        .then((res) => {
          if (res?.data?.success == true)
            // navigate(`/awarded/property/${assignedPropertyId}`);
            navigate(`/tenant`);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error while uploading image", err);
          toast.error(_t("Error while uploading document!"));
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {step == 0 ? (
        <div className="confirm-continue">
          <div className="confirm-left">
            <h3>{_t("Continue to Pay Deposit")}</h3>
            <p>
              {"You Chose to pay €"}{" "}
              <span>
                {formData?.deposit_type == constants.SKOR_DEPOSIT
                  ? +formData?.rent_amount
                  : formData?.deposit_amount}
              </span>{" "}
              {formData?.deposit_type == constants.SKOR_DEPOSIT
                ? _t(`${"Skor Deposit"}`)
                : _t(`${"Full Deposit"}`)}{" "}
            </p>
          </div>
          <div>
            <button className="btn btngreen" onClick={(e) => setStep(1)}>
              {"Confirm & Continue"}{" "}
            </button>
          </div>
        </div>
      ) : (
        <div className="choose-to-pay">
          <h1>
            {_t("You Chose To Pay")}{" "}
            {formData?.deposit_type == constants.SKOR_DEPOSIT
              ? _t(`${"Skor Deposit"}`)
              : _t(`${"Full Deposit"}`)}{" "}
          </h1>
          <h3>
            {formData?.deposit_type == constants.SKOR_DEPOSIT
              ? +formData?.rent_amount
              : formData?.deposit_amount}{" "}
            €
          </h3>

          {/* <button onClick={handlePayNow}>{_t("Pay Now")}</button>
                        <button onClick={e => setShow(true)}>{_t("Upload Payment Confirmation")}</button> */}

          <div className="row">
            <div className="col-12">
              <div className="w-100 paynow-btn" onClick={handlePayNow}>
                <div className="paynow-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                  >
                    <circle cx="24" cy="24" r="24" fill="#253837" />
                    <path
                      d="M26 30.5C23.5 30.5 21.32 29.08 20.24 27H26L27 25H19.58C19.53 24.67 19.5 24.34 19.5 24C19.5 23.66 19.53 23.33 19.58 23H26L27 21H20.24C20.7876 19.9451 21.6143 19.0608 22.63 18.4437C23.6458 17.8265 24.8115 17.5 26 17.5C27.61 17.5 29.09 18.09 30.23 19.07L32 17.3C30.3532 15.8179 28.2156 14.9985 26 15C22.08 15 18.76 17.5 17.5 21H14L13 23H17.06C17 23.33 17 23.66 17 24C17 24.34 17 24.67 17.06 25H14L13 27H17.5C18.76 30.5 22.08 33 26 33C28.31 33 30.41 32.13 32 30.7L30.22 28.93C29.09 29.91 27.62 30.5 26 30.5Z"
                      fill="#A3EB64"
                    />
                  </svg>
                  <p>{_t("Pay Now")}</p>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                  >
                    <path
                      d="M1 18L10 9.5L1 1"
                      stroke="#121212"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="w-100 paynow-btn" onClick={(e) => setShow(true)}>
                <div className="paynow-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                  >
                    <circle cx="24" cy="24" r="24" fill="#253837" />
                    <path
                      d="M32 16H16C14.897 16 14 16.897 14 18V30C14 31.103 14.897 32 16 32H32C33.103 32 34 31.103 34 30V18C34 16.897 33.103 16 32 16ZM20.715 20C21.866 20 22.715 20.849 22.715 22C22.715 23.151 21.866 24 20.715 24C19.564 24 18.715 23.151 18.715 22C18.715 20.849 19.563 20 20.715 20ZM24.43 28H17V27.535C17 26.162 18.676 24.75 20.715 24.75C22.754 24.75 24.43 26.162 24.43 27.535V28ZM31 27H27V25H31V27ZM31 23H26V21H31V23Z"
                      fill="#A3EB64"
                    />
                  </svg>
                  <p>{_t("Upload Payment Confirmation")}</p>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                  >
                    <path
                      d="M1 18L10 9.5L1 1"
                      stroke="#121212"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        className="modal-uploadpdf"
        show={show}
        onHide={(e) => setShow(false)}
      >
        <Modal.Header closeButton>{_t("Select Document")}</Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="formcontrolbar">
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
              // multiple={fileType != constants.income_tax_return}
              accept="application/pdf"
            />
            <Button className="btn-green" onClick={handleFileUpload}>
              {_t("Upload")}
            </Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RentalDepositPay;
