module.exports = {
  employed: "employed",
  self_employed: "self_employed",
  student: "student",
  unemployed: "unemployed",
  retired: "retired",
  pension_certificate: "Pension certificate",
  last_revalorization_letter: "Last revalorization letter",
  national_id: "National Id",
  university_acceptance_letter: "University Acceptance Letter",
  ie_student: "Student registration contract",
  guarantor: "Guarantor",
  employment_contract: "Employment contract",
  payslips: "Last 3 payslips",
  previous_working_life_certificate: "Working life certificate",
  income_tax_return: "Last annual Tax Report (Model 100)",
  deduction_certificate: "Deduction certificate",
  public_appointment_certificate:
    "Public appointment certificate (only if public employee)",
  quarterly_vat: "Last quarterly VAT tax (Form 303)",
  fractioned_tax_return: "Last Fractionated Tax Report (Model 130)",
  self_employed_certificate: "Self-Employed Registration Certificate",
  corporate_tax_return: "Last year's Corporate Tax Return",
  corporate_balance_sheet: "Last year's Corporate balance sheet",
  corporate_PL: "Last year's Corporate P&L",
  TENANT_PENDING: "tenant_pending",
  AWARDED: "awarded",
  LANDLORD_PENDING: "landlord_pending",
  DEPOSIT: "deposit",
  RENT: "rent",
  PENDING: "pending",
  COMPLETED: "completed",
  SUBSCRIPTION_PERCENTAGE: 0.1,
  SKOR_DEPOSIT: "skor_deposit",
  FULL_DEPOSIT: "full_deposit",
  PROFILE_COMPLETE_POINTS: 20,
  DOCS_COMPLETE_POINTS: 50,
  ASSIGNED_PROPERTY: "assigned_property",
  RENTAL_INFO: "rental_info",
  PARTIAL_RENT: "partial rent",
};
