import { Modal } from "react-bootstrap"
import useTranslator from "../hooks/useTranslator"
import { useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import AuthContext from "../context/auth/AuthContext"
import { haveValue } from "../helper/common"
import TenantContext from "../context/tenant/TenantContext"



const AlreadyGuarantorOf = (props) => {

    const { _t } = useTranslator()
    const { alreadyGurantorOf, setAlreadyGurantorOf, gurantorPresent, setGurantorPresent } = props
    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])

    const handleHideModal = () => {
        setAlreadyGurantorOf(false)
        setGurantorPresent(false)
        removeCookie('gurantorFor', { maxAge: 1800 })
    }

    return (
        <>
            <Modal show={alreadyGurantorOf} onHide={handleHideModal} centered className="newmodalui asguarantor">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="already_guarantor">
                        <img src="../../images/have_guarantor.svg" alt="" />
                        {
                            gurantorPresent ? 
                            <h4>{_t("User Associated to mentioned email is already a Guarantor for another Tenant.")}</h4>
:
                            <h4>{_t("As you have a Guarantor, you cannot be Guarantor of any Tenant.")}</h4>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AlreadyGuarantorOf