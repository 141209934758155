import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGGED_IN_TRUE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  TOGGLE_LOGIN,
  TOGGLE_LOADING,
  SHOW_ALERT,
  REMOVE_ALERT,
  USER_DATA_LOADED,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  SET_USER_TYPE,
  CARD_DATA,
  SETOTP_PAGE,
  USER_VERIFIED,
  USER_UNVERIFIED,
  NOTIFICATION_LOADED,
  TOGGLE_BANK_LOADING,
  TOGGLE_ALGOAN_LOADING,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        msg: "Registered Successfully",
        otppage: 2,
      };
    case LOGGED_IN_TRUE:
      return {
        ...state,
        isLoggedIn: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: action.payload,
        msg: "Registration failure",
      };
    case USER_VERIFIED:
      return {
        ...state,
        verified: true,
      };
    case USER_UNVERIFIED:
      return {
        ...state,
        verified: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        msg: action.payload,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case SETOTP_PAGE:
      return {
        ...state,
        otppage: action.payload,
      };
    case TOGGLE_LOGIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TOGGLE_BANK_LOADING:
      return {
        ...state,
        bankLoading: action.payload,
      };
    case TOGGLE_ALGOAN_LOADING:
      return {
        ...state,
        algoanLoading: action.payload,
      };
    case USER_LOADED:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case CARD_DATA:
      return {
        ...state,
        cardData: action.payload
      }

    case NOTIFICATION_LOADED:
      console.log(action.payload);
      return {
        ...state,
        notifications: action.payload,
      };

    default:
      return state;
  }
};
