import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./Utils/i18n";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(
  "pk_test_51NicRRSEz5zd53ew3ZsFt9lKrBq4TZhTBx0cYiHP1AZ3VrnPRGQMFHSSEz46dXjsNfJTcjmTJvQzSoAsyb1UmDqz00237bzGSL"
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </CookiesProvider>
    </I18nextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
