import React, { useReducer, useState, useNavigate } from "react";
import axios from "axios";
import LandLordContext from "./LandLordContext";
import LandLordReducer from "./LandLordReducer";

import {
  BASICSHARED_LOADED,
  DEMO_LANDLORD,
  ERROR_RESOLVE,
  FULLSHARED_LOADED,
  LANDLORD_LOADED,
  LOAD_PROPERTY,
  PROPERTY_ADDED,
  PROPERTY_DELETED,
  PROPERTY_EDITED,
  PROPERTY_ERROR,
  PROPERTY_LOADED,
  SET_PROPERTY,
  SHARED_PROFILE_FAILED_LOADING,
  TOGGLE_LOADING,
} from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";

const LandLordState = (props) => {
  const { _t } = useTranslator();
  const initialState = {
    fullProfileReceived: [],
    basicProfileReceived: [],
    properties: [],
    landlords: [],
    current_property: null,
    landlord_loading: false,
    landlord_error: null,
  };

  const [state, dispatch] = useReducer(LandLordReducer, initialState);

  const setLandlordLoading = (value) => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: value,
    });
  };

  const setErrorResolve = () => {
    dispatch({
      type: ERROR_RESOLVE,
    });
  };

  const loadAllLandlords = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/landlords/alllandlords`, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: LANDLORD_LOADED,
          payload: res.data.landlords,
        });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const setProperties = (properties) => {
    dispatch({
      type: PROPERTY_LOADED,
      payload: properties,
    });
  };

  const loadProperties = async (landlordid) => {
    setLandlordLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/properties/${landlordid}`, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        console.log("props", res);
        let server_response = res;
        dispatch({
          type: LOAD_PROPERTY,
          payload: server_response.data.properties,
        });
      })
      .catch((err) => {
        let server_error = err;
        dispatch({
          type: PROPERTY_ERROR,
          payload: server_error.message,
        });
      });
  };

  const setCurrentProperty = (property) => {
    dispatch({
      type: SET_PROPERTY,
      payload: property,
    });
  };

  const addProperty = async (landlordid, property) => {
    setLandlordLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/properties/${landlordid}`,
        property,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let server_response = res;
        console.log(server_response);
        toast.success(_t("Property saved"));
        dispatch({
          type: PROPERTY_ADDED,
          payload: server_response.data.properties,
        });
      })
      .catch((err) => {
        let server_error = err;
        console.log(server_error);
        toast.error(server_error.message);
        dispatch({
          type: PROPERTY_ERROR,
          payload: server_error.message,
        });
      });
  };

  const editProperty = async (landlordid, propertyid, property) => {
    setLandlordLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/properties/${propertyid}/${landlordid}`,
        property,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let server_response = res;
        console.log(server_response);
        toast.success(_t("Property info changed"));
        loadProperties(landlordid);
        dispatch({
          type: PROPERTY_EDITED,
          payload: server_response.data.property,
        });
      })
      .catch((err) => {
        let server_error = err;
        console.log(server_error);
        toast.error(server_error.message);
        dispatch({
          type: PROPERTY_ERROR,
          payload: server_error.message,
        });
      });
  };

  const deleteProperty = async (propertyid, landlordid) => {
    setLandlordLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/properties/${propertyid}/${landlordid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let server_response = res;
        console.log(server_response);
        toast.warning(_t("Property deleted"));
        dispatch({
          type: PROPERTY_DELETED,
          payload: propertyid,
        });
      })
      .catch((err) => {
        let server_error = err;
        console.log(server_error);
        toast.error(server_error.message);
        dispatch({
          type: PROPERTY_ERROR,
          payload: server_error.message,
        });
      });
  };

  const requestFullProfile = async (
    landlordid,
    tenantid,
    sharedprofileid,
    propertypayload
  ) => {
    console.log(landlordid, tenantid, sharedprofileid, propertypayload);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/landlords/request-profile/${landlordid}/${tenantid}/${sharedprofileid}`,
        propertypayload,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        if (serverResponse.data.status == "success") {
          toast.success(_t("Full Profile Request sent to Tenant"));
        }
      })
      .catch((err) => {
        let err_res = err;
        console.log(err_res);
        toast.error("Error", err.response);
      });
  };

  const loadProfilesLandlord = async (tenantid, profiletype) => {
    setLandlordLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/landlord-received/${tenantid}?profiletype=${profiletype}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let serverResponse = res.data;
        console.log(serverResponse);
        if (profiletype == "full") {
          dispatch({
            type: FULLSHARED_LOADED,
            payload: serverResponse,
          });
        }
        if (profiletype == "basic") {
          dispatch({
            type: BASICSHARED_LOADED,
            payload: serverResponse,
          });
        }
        setLandlordLoading(false);
      })
      .catch((err) => {
        let serverResponse = err;
        dispatch({
          type: SHARED_PROFILE_FAILED_LOADING,
          payload: serverResponse,
        });
        setLandlordLoading(false);
      });
  };

  const getAutoCompleteList = async (keywords) => {
    const resp = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/landlords/getAutoComplete?keywords=${keywords}`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    var serverResponse = resp.data;
    if (
      haveValue(resp?.data?.data?.from_ref) &&
      resp?.data?.data?.from_ref == true
    ) {
      return serverResponse.data;
    } else {
      return serverResponse.data.results;
    }
  };

  const validateCadastre = async (code_ine, cod_via, numero_via) => {
    const resp = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/landlords/validateCadastre?code_ine=${code_ine}&cod_via=${cod_via}&numero_via=${numero_via}`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    let serverResponse = resp.data;
    return serverResponse.data.results;
  };

  const getBuildingData = async (reference, cod_via, numero_via) => {
    const resp = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/landlords/getBuildingData?reference=${reference}&cod_via=${cod_via}&numero_via=${numero_via}`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    let serverResponse = resp.data;
    return serverResponse.data.results;
  };

  const editSharedProfile = async (sharedprofileid, data) => {
    setLandlordLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/editShared/${sharedprofileid}`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let server_response = res;
        console.log(server_response);
        toast.success(_t("Property Linked"));
        // loadProperties(landlordid);
        dispatch({
          type: PROPERTY_EDITED,
          payload: server_response.data.property,
        });
      })
      .catch((err) => {
        let server_error = err;
        console.log(server_error);
        toast.error(server_error.message);
      });
  };

  const getPropertyDetails = async (property_id = null) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/properties/property-info/${property_id}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getSharedProfile = async (sharedprofileid) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/singleshared/${sharedprofileid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const initiateAssignProperty = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/landlords/initiateAssignProperty`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const fetchAssignedProperties = async (landlordId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/landlords/load/assigned/properties/${landlordId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const fetchAssignedDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/landlords/load/assigned/details/${id}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      console.log(response, "responseresponseresponseresponseresponse");
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const loadRentLeger = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/landlords/load/rent/ledger/${id}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const endLease = async (assignPropId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/landlords/end/lease/${assignPropId}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const saveRentPercentage = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/landlords/save/rent/percentage`,
        data,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <LandLordContext.Provider
      value={{
        properties: state.properties,
        current_property: state.current_property,
        landlord_error: state.landlord_error,
        landlord_loading: state.landlord_loading,
        fullProfileReceived: state.fullProfileReceived,
        basicProfileReceived: state.basicProfileReceived,
        loadAllLandlords,
        setProperties,
        loadProperties,
        setCurrentProperty,
        addProperty,
        editProperty,
        deleteProperty,
        setLandlordLoading,
        requestFullProfile,
        setErrorResolve,
        loadProfilesLandlord,
        getAutoCompleteList,
        validateCadastre,
        getBuildingData,
        editSharedProfile,
        getPropertyDetails,
        getSharedProfile,
        initiateAssignProperty,
        fetchAssignedProperties,
        fetchAssignedDetails,
        loadRentLeger,
        endLease,
        saveRentPercentage,
      }}
    >
      {props.children}
    </LandLordContext.Provider>
  );
};

export default LandLordState;
